import { hideNotification } from "@mantine/notifications";
import {
	showNotificationError,
	showNotificationLoading,
	showNotificationSuccess,
} from "@reusable/Notifications/notificationSystem";
import { sendNotificationsPromotions } from "@services/notifications/notifications";
import { useMutation } from "@tanstack/react-query";

export interface ISmsNotificationPromotions {
	smsMessageText: string;
	phoneNumberList: string[];
	campaignName: string;
	lastDayOfCampaign: Date;
}
export const useSendNotificationPromotions = () => {
	const randomId = Math.random().toString(36).substring(7);
	return useMutation<any, Error, ISmsNotificationPromotions>(
		//@ts-ignore
		(data) => sendNotificationsPromotions(data),
		{
			onSuccess: async () => {
				showNotificationSuccess("Campanie de SMS-uri trimisă cu succes!");
				hideNotification(randomId);
			},
			onError: async (error: string) => {
				showNotificationError("Eroare la trimiterea SMS-urilor!");
				hideNotification(randomId);
			},
			onMutate: async () => {
				showNotificationLoading("Creeare campanie...", randomId);
			},
		},
		{ useErrorBoundary: true }
	);
};

export default useSendNotificationPromotions;
