import {
	ActionIcon,
	Flex,
	Menu,
	NumberInput,
	Select,
	Stack,
	Text,
	TextInput,
	Tooltip,
} from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { IconChevronDown, IconPlus, IconTrash } from "@tabler/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { EFieldType, IExtraOption, IOffer } from "../../interfaces/IOffer";
import { extraOrdersArray, extraSMSArray } from "../../utils/dataArrays";

const defaultTempExtra: { value: number | undefined; label: string } = {
	value: undefined,
	label: "",
};

interface IExtraOptionsSectionProps {
	offerForm: UseFormReturnType<IOffer, (values: IOffer) => IOffer>;
}

export default function ExtraOptionsSection({ offerForm }: IExtraOptionsSectionProps) {
	const { t } = useTranslation();
	const extraOrdersArr = extraOrdersArray(t);
	const extraSmsArr = extraSMSArray(t);
	const [tempExtra, setTempExtra] = useState(defaultTempExtra);

	const removeExtraFeature = (i: number) => {
		offerForm.removeListItem("features.extra", i);
	};

	const addExtraOption = (fieldType: EFieldType) => {
		switch (fieldType) {
			case EFieldType.TEXT:
				if (tempExtra.label && typeof tempExtra.value === "number") {
					offerForm.insertListItem("features.extra", {
						fieldType: EFieldType.TEXT,
						label: tempExtra.label,
						value: tempExtra.value,
					});
					setTempExtra(defaultTempExtra);
				}
				break;
			case EFieldType.ORDER:
				offerForm.insertListItem("features.extra", {
					fieldType: EFieldType.ORDER,
					label: extraOrdersArr[0].label,
					value: +extraOrdersArr[0].value,
				});
				break;
			case EFieldType.SMS:
				offerForm.insertListItem("features.extra", {
					fieldType: EFieldType.SMS,
					label: extraSmsArr[0].label,
					value: +extraSmsArr[0].value,
				});

				break;
		}
	};

	const editExtra = (toSet: "order" | "sms", val: string, i: number) => {
		let temp: any;
		switch (toSet) {
			case "order":
				temp = {
					...extraOrdersArr.find((item) => item.value === val),
					fieldType: EFieldType.ORDER,
				};
				break;
			case "sms":
				temp = { ...extraSmsArr.find((item) => item.value === val), fieldType: EFieldType.SMS };
				break;
			default:
				break;
		}
		if (temp)
			offerForm.setFieldValue(
				"features.extra",
				offerForm.values.features.extra.map((item: IExtraOption, index: number) => {
					if (index === i) {
						return {
							fieldType: temp.fieldType,
							label: temp.label,
							value: +temp.value,
						};
					} else return item;
				})
			);
	};

	const returnExtraOptionValue = (field: EFieldType.ORDER | EFieldType.SMS) => {
		return offerForm.values.features.extra.find((item: IExtraOption) => item.fieldType === field);
	};

	const returnFieldType = (fieldType: EFieldType, i: number, option: IExtraOption) => {
		switch (fieldType) {
			case EFieldType.TEXT:
				return (
					<TextInput
						disabled
						defaultValue={option.label}
						w="100%"
						classNames={{
							input: "input-styles",
						}}
					/>
				);
			case EFieldType.ORDER:
				return (
					<Select
						onChange={(val) => editExtra("order", val!, i)}
						value={returnExtraOptionValue(EFieldType.ORDER)?.value.toString()}
						w="100%"
						data={extraOrdersArr}
						classNames={{
							input: "input-styles",
							item: "select-item",
						}}
					/>
				);
			case EFieldType.SMS:
				return (
					<Select
						onChange={(val) => editExtra("sms", val!, i)}
						value={returnExtraOptionValue(EFieldType.SMS)?.value.toString()}
						w="100%"
						data={extraSmsArr}
						classNames={{
							input: "input-styles",
							item: "select-item",
						}}
					/>
				);
		}
	};

	return (
		<Stack w="100%" spacing={24}>
			<Text size={20} fw={600} color="var(--heading)">
				Optiuni incluse
			</Text>
			<Flex align="flex-end" gap={16}>
				<Text
					style={{
						opacity: 0,
					}}
					size={16}
					color="var(--heading)"
				>
					0.
				</Text>
				<TextInput
					value={tempExtra.label}
					onChange={(e) =>
						setTempExtra((prev) => ({
							...prev,
							label: e.target.value,
						}))
					}
					w="100%"
					label="Optiune extra"
					classNames={{
						input: "input-styles",
					}}
				/>
				<NumberInput
					value={tempExtra.value}
					onChange={(val) =>
						setTempExtra((prev) => ({
							...prev,
							value: val || 0,
						}))
					}
					w="100%"
					rightSectionWidth={60}
					rightSection="€/luna"
					label="Valoare"
					classNames={{
						input: "input-styles",
					}}
					hideControls
					precision={2}
				/>
				<Flex miw={80}>
					<Tooltip withArrow label="Adauga">
						<ActionIcon
							onClick={() => addExtraOption(EFieldType.TEXT)}
							w="100%"
							style={{ border: "1px solid var(--divider)", borderRadius: "8px 0 0 8px" }}
							maw={48}
							h={48}
							variant="outline"
						>
							<IconPlus stroke={2} color="var(--heading)" />
						</ActionIcon>
					</Tooltip>
					<Menu
						styles={{
							dropdown: {
								borderRadius: 8,
							},
						}}
					>
						<Tooltip withArrow label="Mai multe">
							<Menu.Target>
								<ActionIcon
									onClick={() => addExtraOption(EFieldType.TEXT)}
									w="100%"
									style={{
										border: "1px solid var(--divider)",
										borderLeft: "none",
										borderRadius: "0 8px 8px 0",
									}}
									maw={32}
									h={48}
									variant="outline"
								>
									<IconChevronDown stroke={2} color="var(--heading)" />
								</ActionIcon>
							</Menu.Target>
						</Tooltip>
						<Menu.Dropdown>
							<Menu.Item
								disabled={Boolean(returnExtraOptionValue(EFieldType.ORDER)?.label)}
								icon={<IconPlus size={18} />}
								onClick={() => addExtraOption(EFieldType.ORDER)}
							>
								Extra comenzi
							</Menu.Item>
							<Menu.Item
								disabled={Boolean(returnExtraOptionValue(EFieldType.SMS)?.label)}
								icon={<IconPlus size={18} />}
								onClick={() => addExtraOption(EFieldType.SMS)}
							>
								Extra SMS-uri
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</Flex>
			</Flex>
			{offerForm.values.features.extra.map((option: IExtraOption, i: number) => {
				return (
					<Flex key={i} align="flex-end" gap={16}>
						<Text mb={8} size={16} color="var(--heading)">
							{i + 1}.
						</Text>
						{returnFieldType(option.fieldType, i, option)}
						<NumberInput
							disabled
							value={option.value}
							w="100%"
							min={0}
							rightSectionWidth={60}
							rightSection="€/luna"
							classNames={{
								input: "input-styles",
							}}
							hideControls
							precision={2}
						/>
						<Tooltip withArrow label="Sterge">
							<ActionIcon
								onClick={() => removeExtraFeature(i)}
								w="100%"
								style={{ border: "1px solid var(--divider)" }}
								radius={8}
								maw={48}
								h={48}
								variant="outline"
							>
								<IconTrash stroke={2} color="var(--error)" />
							</ActionIcon>
						</Tooltip>
					</Flex>
				);
			})}
		</Stack>
	);
}
