import axiosInstance from "@services/axiosInstance";

export default async function retrySendingSmsNotification(notificationId: string) {
	try {
		const res = await axiosInstance.post(`v2/retry-sending/${notificationId}`);

		if (res.status === 200) {
			return res.data;
		} else throw new Error(`Unable to get the sms notifications, status ${res.status}`);
	} catch (error) {
		console.log(error);
		// @ts-ignore
		throw new Error(error.message);
	}
}
