import { hideNotification } from "@mantine/notifications";
import {
	showNotificationError,
	showNotificationLoading,
	showNotificationSuccess,
} from "@reusable/Notifications/notificationSystem";
import { createOffer } from "@services/offer/offer";
import { useMutation } from "@tanstack/react-query";
import { IOffer } from "@views/pages/register/interfaces/IOffer";
import { useTranslation } from "react-i18next";

export default function useCreateOffer() {
	const randomId = Math.random().toString(36).substring(7);
	const { t } = useTranslation();

	return useMutation(
		({ data, html }: { data: IOffer; html: string }) => createOffer({ ...data, html }),
		{
			retry: false,
			onSuccess: async () => {
				showNotificationSuccess(t("Success"));
				hideNotification(randomId);
			},
			onError: async (error: string) => {
				showNotificationError(t("Error"));
				hideNotification(randomId);
			},
			onMutate: async () => {
				showNotificationLoading(t("Loading..."), randomId);
			},
		}
	);
}
