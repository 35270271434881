import { useForm } from "@mantine/form";
import { IOffer } from "../interfaces/IOffer";
import { generateId } from "./generateId";

export default function useOfferForm() {
	const offerForm = useForm<IOffer>({
		initialValues: {
			name: "",
			price: 0,
			startSubscription: new Date(),
			features: {
				extra: [],
			},
			companyName: "",
			companyCUI: "",
			companyJ: "",
			companyAddress: "",
			companyAdministratorName: "",
			companyEmail: "",
			companyPhone: "",
			offerShortId: generateId(), // Initialize with an ID
			language: "ro",
			invoicePeriod: 1,
		},
		validateInputOnBlur: true,
		validate: {},
	});

	// Function to regenerate the offerShortId
	const regenerateId = () => {
		const newId = generateId();
		offerForm.setFieldValue("offerShortId", newId); // Update the field value in the form
		return newId; // Optionally return the new ID
	};

	return { offerForm, regenerateId };
}
