import { Button, createStyles, Menu, Tooltip } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons";
import { cloneElement, ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";

const useStyles = createStyles(() => ({
	dropdown: {
		borderRadius: 8,
		padding: 0,
	},
	item: {
		color: "var(--body)",
		fontSize: 14,
		paddingBlock: 12,
	},
	itemIcon: {
		marginRight: 8,
	},
}));

export interface IMenuItem {
	label: string;
	icon: ReactNode;
	onClick: () => void;
	disabled?: boolean;
	danger?: boolean;
	excluded?: boolean;
	tooltip?: string;
}

interface IMenuButtonProps {
	items: IMenuItem[];
	label?: string;
	width?: number | "target";
	disabled?: boolean;
	customButton?: ReactNode;
	withTooltip?: boolean;
}

export default function MenuButton({
	items,
	label,
	width = 200,
	disabled,
	customButton,
	withTooltip,
}: IMenuButtonProps) {
	const { t } = useTranslation();
	label = !label ? t("general.moreButton") : label;
	const { classes } = useStyles();
	const customButtonComponent =
		customButton &&
		cloneElement(customButton as ReactElement<any>, {
			disabled,
		});
	const returnIconWithSytle = (
		iconToStyle: ReactNode,
		color: string | undefined = "var(--body)"
	) => {
		const tempIcon = cloneElement(iconToStyle as ReactElement<any>, {
			size: 16,
			color: color,
			style: {
				flexShrink: 0,
			},
		});
		return tempIcon;
	};

	const returnDisabledStyles = (defaultColor: string, disabled?: boolean) => {
		let tempColor = defaultColor;
		if (disabled) tempColor = "var(--grey)";
		return tempColor;
	};

	return (
		<Menu
			withinPortal={true}
			classNames={{
				dropdown: classes.dropdown,
				item: classes.item,
				itemIcon: classes.itemIcon,
			}}
			width={width}
		>
			<Menu.Target>
				{customButtonComponent ? (
					<Tooltip disabled={!withTooltip} withArrow label={t("general.moreButton")}>
						{customButtonComponent}
					</Tooltip>
				) : (
					<Button
						disabled={disabled}
						style={{
							paddingRight: 14,
						}}
						rightIcon={
							<IconChevronDown color="var(--heading)" size={18} style={{ flexShrink: 0 }} />
						}
						color="dark"
						variant="outline"
						classNames={{
							root: "button-style",
						}}
					>
						{label}
					</Button>
				)}
			</Menu.Target>

			<Menu.Dropdown>
				{items
					.filter((item) => !item.excluded)
					.map((item, i) => {
						const border = item.danger ? "1px solid var(--divider40)" : "none";
						const fontColor = item.danger ? "var(--error)" : "var(--body)";
						const correctColor = returnDisabledStyles(fontColor, item.disabled);
						return (
							<Tooltip
								key={i}
								withArrow
								position="left"
								disabled={!item.tooltip}
								label={item.tooltip}
							>
								<Menu.Item
									style={{
										fontSize: 14,
										color: correctColor,
										borderTop: border,
									}}
									disabled={item.disabled}
									onClick={() => item.onClick()}
									icon={returnIconWithSytle(item.icon, correctColor)}
								>
									{item.label}
								</Menu.Item>
							</Tooltip>
						);
					})}
			</Menu.Dropdown>
		</Menu>
	);
}
