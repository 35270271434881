import { Flex, NumberInput, Select, Stack, Text } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { IOffer } from "../../interfaces/IOffer";
import { supportOptions } from "../../utils/dataArrays";

interface IIncludedOptionsSectionProps {
	offerForm: UseFormReturnType<IOffer, (values: IOffer) => IOffer>;
}

export default function IncludedOptionsSection({ offerForm }: IIncludedOptionsSectionProps) {
	const { t } = useTranslation();
	return (
		<Stack w="100%" spacing={24}>
			<Text size={20} fw={600} color="var(--heading)">
				Optiuni incluse
			</Text>
			<Flex gap={24}>
				<NumberInput
					w="100%"
					{...offerForm.getInputProps("features.includedDrivers")}
					min={1}
					label="Soferi"
					classNames={{
						input: "input-styles",
					}}
				/>
				<NumberInput
					w="100%"
					{...offerForm.getInputProps("features.includedOrders")}
					min={0}
					rightSectionWidth={60}
					rightSection="/luna"
					label="Comenzi"
					classNames={{
						input: "input-styles",
					}}
					hideControls
				/>
				<NumberInput
					w="100%"
					{...offerForm.getInputProps("features.includedSMS")}
					min={0}
					rightSectionWidth={60}
					rightSection="/luna"
					label="SMS-uri"
					classNames={{
						input: "input-styles",
					}}
					hideControls
				/>
			</Flex>
			<Select
				{...offerForm.getInputProps("features.includedSupport")}
				w="100%"
				label="Suport"
				data={supportOptions(t)}
				classNames={{
					input: "input-styles",
					item: "select-item",
				}}
			/>
		</Stack>
	);
}
