interface IPlanIconProps {
	amount: number;
	baseSize?: {
		width: string;
		height: string;
	};
    top?: number
}

export default function PlanIcon({
	amount,
	baseSize = { height: "18px", width: "32px" },
    top = 8
}: IPlanIconProps) {
	const items = Array(amount).fill(undefined);

	const color = (index: number) => {
		const max = 1;
		const min = 0;
		const step = (max - min) / amount;
		const alpha = max - step * index;
		const newAlpha = Math.max(min, Math.min(max, alpha));
		return `rgba(233, 167, 8, ${newAlpha})`;
	};

	return (
		<div
			style={{
				position: "relative",
				width: "32px",
				zIndex: amount + 1,
			}}
		>
			{items.map((_, i) => (
				<div
					style={{
						position: "absolute",
						zIndex: i - 1,
						top: `${i * top}px`,
						scale: i === 0 ? 1 : `1.${i * amount}`,
					}}
					key={i}
				>
					<svg
						width={baseSize.width}
						height={baseSize.height}
						viewBox="0 0 25 15"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						style={{}}
					>
						<path
							d="M14.6734 0.814487L24.011 6.08204C24.2267 6.20369 24.4062 6.38081 24.5311 6.5951C24.6561 6.80947 24.7219 7.05326 24.7219 7.30159C24.7219 7.54992 24.6561 7.79372 24.5311 8.00809C24.4062 8.22238 24.2267 8.39949 24.011 8.52115L14.6735 13.7889C14.0776 14.1251 13.4055 14.3018 12.722 14.3018C12.0383 14.3018 11.3663 14.1251 10.7704 13.7889L1.43285 8.52115C1.21721 8.39949 1.03768 8.22238 0.912732 8.00809C0.787788 7.79372 0.721923 7.54992 0.721923 7.30159C0.721923 7.05326 0.787788 6.80947 0.912732 6.5951C1.03768 6.38081 1.21721 6.20369 1.43285 6.08204L10.7704 0.814487C11.3663 0.4783 12.0383 0.301758 12.722 0.301758C13.4055 0.301758 14.0776 0.4783 14.6734 0.814487Z"
							fill={color(i)}
						/>
					</svg>
				</div>
			))}
		</div>
	);
}
