import { hideNotification } from "@mantine/notifications";
import {
	showNotificationError,
	showNotificationLoading,
	showNotificationSuccess,
} from "@reusable/Notifications/notificationSystem";
import { deleteNotification } from "@services/notifications/appNotifications";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export default function useDeleteAppNotification() {
	const randomId = Math.random().toString(36).substring(7);
	const { t } = useTranslation();

	return useMutation((notificationId: string) => deleteNotification(notificationId), {
		retry: false,
		onSuccess: async () => {
			showNotificationSuccess(t("Notification deleted successfully"));
			hideNotification(randomId);
		},
		onError: async (error: string) => {
			showNotificationError(t("Error deleting notification"));
			hideNotification(randomId);
		},
		onMutate: async () => {
			showNotificationLoading(t("Deleting notification..."), randomId);
		},
	});
}
