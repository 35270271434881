import { hideNotification } from "@mantine/notifications";
import {
	showNotificationError,
	showNotificationLoading,
	showNotificationSuccess,
} from "@reusable/Notifications/notificationSystem";
import { createNotification } from "@services/notifications/appNotifications";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { NotificationType } from "./useGetAppNotifications";

export interface ICreateAppNotification {
	userRoles: string[];
	organizationIds: string[];
	availableFrom?: Date | null;
	message: string;
	title: string;
	type: NotificationType;
	link?: {
		external: boolean;
		url: string;
	};
}

export default function useCreateAppNotification() {
	const randomId = Math.random().toString(36).substring(7);
	const { t } = useTranslation();

	return useMutation((data: ICreateAppNotification) => createNotification(data), {
		retry: false,
		onSuccess: async () => {
			showNotificationSuccess(t("Success"));
			hideNotification(randomId);
		},
		onError: async (error: string) => {
			showNotificationError(t("Error"));
			hideNotification(randomId);
		},
		onMutate: async () => {
			showNotificationLoading(t("Loading..."), randomId);
		},
	});
}
