import { Flex, Stack, Text, TextInput } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { IOffer } from "../../interfaces/IOffer";
import OrganizationSelect from "./OrganizationSelect";

interface ICompanySectionProps {
	offerForm: UseFormReturnType<IOffer, (values: IOffer) => IOffer>;
}

export default function CompanySection({ offerForm }: ICompanySectionProps) {
	return (
		<Stack w="100%" spacing={24}>
			<Text size={20} fw={600} color="var(--heading)">
				Beneficiar
			</Text>
			<OrganizationSelect offerForm={offerForm} />
			<TextInput
				{...offerForm.getInputProps("companyName")}
				label="Companie"
				classNames={{
					input: "input-styles",
				}}
			/>
			<TextInput
				{...offerForm.getInputProps("companyAdministratorName")}
				label="Administrator"
				classNames={{
					input: "input-styles",
				}}
			/>
			<Flex gap={24} w="100%">
				<TextInput
					{...offerForm.getInputProps("companyCUI")}
					w="100%"
					label="CUI"
					classNames={{
						input: "input-styles",
					}}
				/>
				<TextInput
					{...offerForm.getInputProps("companyJ")}
					w="100%"
					label="Reg. Com."
					classNames={{
						input: "input-styles",
					}}
				/>
			</Flex>
			<TextInput
				{...offerForm.getInputProps("companyAddress")}
				w="100%"
				label="Adresa"
				classNames={{
					input: "input-styles",
				}}
			/>
			<TextInput
				{...offerForm.getInputProps("companyEmail")}
				label="Email"
				classNames={{
					input: "input-styles",
				}}
			/>
			<TextInput
				{...offerForm.getInputProps("companyPhone")}
				label="Telefon"
				classNames={{
					input: "input-styles",
				}}
			/>
		</Stack>
	);
}
