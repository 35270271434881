import { Flex } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import OfferForm from "./components/offerComponents/OfferForm";
import PdfContainer from "./components/pdfPreview/PdfContainer";
import { IOffer } from "./interfaces/IOffer";
import { RefObject } from "react";

interface IOfferProps {
	offerForm: UseFormReturnType<IOffer, (values: IOffer) => IOffer>;
	componentRef: RefObject<HTMLDivElement>
}

export default function Offer({ offerForm, componentRef }: IOfferProps) {
	return (
		<Flex  gap={32}>
			<OfferForm offerForm={offerForm} />
			<PdfContainer componentRef={componentRef} offerForm={offerForm} />
		</Flex>
	);
}
