import { ActionIcon, Flex, Text, Tooltip } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { IconExternalLink, IconEye } from "@tabler/icons";
import localeNumber from "@views/horeca/v2/companyList/utils/localeNumber";
import { useSearchParams } from "@views/horeca/v2/companyList/utils/useSearchParamsCustom";
import { IColumnModel } from "@views/smsNotifications/components/DataTableWrapper";
import DataTableWrapperSimple from "@views/smsNotifications/components/DataTableWrapperSimple";
import TitleAndDescription from "@views/smsNotifications/components/TitleAndDescription";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { IOffer, IOfferResponse } from "../../interfaces/IOffer";
import { calculateOfferTotals } from "../../utils/calculateOfferTotals";
import OfferIframePreview from "../OfferIframePreview";

interface IColumnsProps {
	t: any;
	openAndEditOffer: (offer: IOfferResponse) => void;
}

const columns = ({
	openAndEditOffer,
	t,
}: IColumnsProps): IColumnModel<IExtendedOfferResponse>[] => {
	return [
		{
			key: "index",
			label: "#",
			sorter: true,
			filter: true,
			_style: {
				width: 100,
			},
		},
		{
			key: "offerShortId",
			label: "Id oferta",
			sorter: true,
			filter: true,
		},
		{
			key: "nameAndStart",
			label: "Plan",
			sorter: true,
			filter: true,
			content: (item) => {
				return (
					<TitleAndDescription
						title={item.name}
						description={`Start: ${moment(item.startSubscription).format("ddd, DD MMM YYYY")}`}
					/>
				);
			},
		},
		{
			key: "price",
			label: "Total",
			sorter: true,
			filter: true,
			content: (item) => {
				const { _id, createdAt, updatedAt, offerUrl, organizationId, ...rest } = item;
				return (
					<Text size={14} color="var(--body)">
						{localeNumber({
							value: parseInt(
								calculateOfferTotals({
									...rest,
									language: "ro",
									startSubscription: new Date(item.startSubscription),
								}).total
							),
							currency: "EUR",
							style: "currency",
							locale: "en-US",
						})}
					</Text>
				);
			},
		},
		{
			key: "companyInfo",
			label: "Companie",
			sorter: true,
			filter: true,
			content: (item) => {
				return <TitleAndDescription title={item.companyName} description={item.companyCUI} />;
			},
		},
		{
			key: "companyContact",
			label: "Contact",
			sorter: true,
			filter: true,
			content: (item) => {
				return <TitleAndDescription title={item.companyPhone} description={item.companyEmail} />;
			},
		},
		{
			key: "_id",
			label: "",
			sorter: false,
			filter: false,
			content: (item) => {
				return (
					<Flex gap={16}>
						<OfferIframePreview
							custom={
								<ActionIcon variant="subtle">
									<IconEye color="var(--accent)" />
								</ActionIcon>
							}
							pdfUrl={item.offerUrl}
							title={item.name}
						/>
						<Tooltip withArrow label="Editeaza">
							<ActionIcon onClick={() => openAndEditOffer(item)} variant="subtle">
								<IconExternalLink color="var(--accent)" />
							</ActionIcon>
						</Tooltip>
					</Flex>
				);
			},
		},
	];
};

interface IExtendedOfferResponse extends IOfferResponse {
	index: number;
	companyInfo: string;
	companyContact: string;
	nameAndStart: string;
}

interface IOffersTableProps {
	offers: IOfferResponse[];
	offerForm: UseFormReturnType<IOffer, (values: IOffer) => IOffer>;
}

export default function OffersTable({ offers, offerForm }: IOffersTableProps) {
	const [_, setParams] = useSearchParams();

	const { t } = useTranslation();
	moment.locale("ro");

	const openAndEditOffer = (offer: IOfferResponse) => {
		const { _id, createdAt, updatedAt, offerUrl, organizationId, offerShortId, ...rest } = offer;

		offerForm.setValues({ ...rest, startSubscription: new Date(rest.startSubscription) });

		setParams({ active: "offer" });
	};

	const tableOffers = offers.map((item, i) => ({
		...item,
		index: i + 1,
		companyInfo: `${item.companyName} - ${item.companyCUI}`,
		companyContact: `${item.companyEmail} - ${item.companyPhone}`,
		nameAndStart: `${item.name} - ${moment(item.startSubscription).format("ddd, DD MMM YYYY")}`,
	}));

	return (
		<DataTableWrapperSimple<IExtendedOfferResponse>
			data={tableOffers}
			columns={columns({ t, openAndEditOffer })}
			storeKey="offers-list-table"
		/>
	);
}
