import { getUserNotifications } from "@services/notifications/appNotifications";
import { useQuery } from "@tanstack/react-query";
import { INotificationMetadata } from "@views/pages/register/interfaces/INotification";
import { useSelector } from "react-redux";
export enum NotificationType {
	WorkspaceAlert = "workspace-alert",
	WorkspaceSuccess = "workspace-success",
	SystemAlert = "system-alert",
}
export interface INotification {
	_id: string; // Unique identifier for the notification
	message: string; // The content of the notification
	title: string;
	type: NotificationType; // Type of notification
	read: boolean; // Whether the notification has been read or not
	createdAt: string; // The creation timestamp of the notification
	updatedAt: string; // The last updated timestamp of the notification
	availableFrom?: Date
	metadata?: INotificationMetadata
}
export default function useGetAppNotifications() {
	//@ts-ignore
	const userData = useSelector((state) => state.form.userData);
	const userId = userData.userId;
	// console.log("userId", userId);
	return useQuery(
		["app-notifications" + userId], // Query key
		() => getUserNotifications(userId), // API call to fetch notifications
		{
			retry: false, // Don't retry on failure
			refetchInterval: 60000, // Automatically refetch every 1 minute
			onError: (error) => {
				console.error("Error fetching notifications:", error);
			},
		}
	);
}
