import { getCampaignList } from "@services/notifications/notifications";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";

export const useGetCampaignList = () => {
	const organizationId = useSelector((state: any) => state.form.organizationSelectedId);

	return useQuery(
		["campaign-list-" + organizationId],
		() => {
			if (organizationId) {
				return getCampaignList();
			} else {
				return [];
			}
		},
		{ retry: false }
	);
};
