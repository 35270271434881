import { ActionIcon, Button, Flex, Popover, Radio, Stack, Text, Tooltip } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { IconCheck, IconSettings } from "@tabler/icons";
import { useSearchParams } from "@views/horeca/v2/companyList/utils/useSearchParamsCustom";
import { actionIconStyles } from "@views/orders/v2/readyForDelivery/components/ComponentsStyle";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ITabProps } from "./CustomTabs";

interface ITabsOptionsPopoverProps {
	tabs: ITabProps[];
	pageKey?: string;
}

export default function TabsOptionsPopover({ tabs, pageKey }: ITabsOptionsPopoverProps) {
	const { t } = useTranslation();
	const [defaultTabs, setDefaultTabs, removeItem] = useLocalStorage<any>({
		key: "defaultTabs",
	});
	const [searchParams, setSearchParams] = useSearchParams();
	const activeTab = searchParams.get("active");
	const [toolTipMessage, setToolTipMessage] = useState<any>("");
	const changeDefaultTab = (val: string) => {
		if (pageKey) {
			const tempTabs = { ...defaultTabs, [pageKey]: val };
			setDefaultTabs(tempTabs);
			if (activeTab !== val) {
				setSearchParams(
					{ active: val },
					{
						merge: true,
					}
				);
			}
		}
	};

	const resetAll = () => {
		removeItem();
		setToolTipMessage(
			<Flex align="center" w="fit-content" gap={4}>
				<IconCheck size={16} />
				<Text
					style={{
						whiteSpace: "nowrap",
					}}
				>
					{t("ordersTable.collectedOrders.tabOptions.resetMessage")}
				</Text>
			</Flex>
		);

		setTimeout(() => {
			setToolTipMessage("");
		}, 2000);
	};

	const isChecked = (tab: string) => {
		if (pageKey && defaultTabs) {
			const tempVal = defaultTabs[pageKey];
			return tempVal === tab;
		}
		return false;
	};

	return (
		<Popover
			withArrow
			radius={8}
			styles={{
				dropdown: {
					zIndex: 3000,
					boxShadow: "var(--sh)",
					padding: 24,
				},
			}}
			withinPortal={true}
		>
			<Popover.Target>
				<Tooltip label={t("settings.v2.smsSettings.templateModal.optionsLabel")} withArrow>
					<ActionIcon size={actionIconStyles.size}>
						<IconSettings color="var(--heading)" />
					</ActionIcon>
				</Tooltip>
			</Popover.Target>
			<Popover.Dropdown>
				<Stack spacing={16}>
					<Stack spacing={4}>
						<Flex gap={16} justify="space-between" align="center">
							<Text size={24} fw={600}>
								{t("ordersTable.collectedOrders.tabOptions.title")}
							</Text>
							<Tooltip
								openDelay={300}
								withArrow
								multiline
								label={
									toolTipMessage || (
										<Text w={250}>{t("ordersTable.collectedOrders.tabOptions.tooltip")}</Text>
									)
								}
							>
								<Button
									onClick={resetAll}
									variant="subtle"
									style={{
										padding: 0,
										width: "fit-content",
										background: "none",
										fontWeight: 400,
										textDecoration: "underline",
										color: "var(--bodyLight)",
									}}
								>
									{t("general.buttonReset")}
								</Button>
							</Tooltip>
						</Flex>
						<Text maw={210} size={14} color="var(--bodyLight)">
							{t("ordersTable.collectedOrders.tabOptions.description")}
						</Text>
					</Stack>
					<div
						style={{
							overflowY: "auto",
							height: "100%",
							maxHeight: 250,
						}}
					>
						<Stack spacing={16}>
							{tabs.map((tab, i) => (
								<Radio
									key={i}
									color="yellow"
									onChange={(e) => {
										changeDefaultTab(e.currentTarget.value);
									}}
									value={tab.value}
									label={tab.label}
									checked={isChecked(tab.value)}
									disabled={tab.disabled}
								/>
							))}
						</Stack>
					</div>
				</Stack>
			</Popover.Dropdown>
		</Popover>
	);
}
