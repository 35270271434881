import { Flex, Text } from "@mantine/core";
import { IconBulb } from "@tabler/icons";

interface ITipProps {
	tip: string;
	tipCount?: string
}

export default function Tip({ tip, tipCount }: ITipProps) {
	return (
		<Flex gap={4}>
			<IconBulb style={{ flexShrink: 0 }} stroke={2} size={20} color="var(--bodyLight)" />
			<Text color="var(--bodyLight)" size={14}>
				<Text component="span" color="var(--bodyLight)" fw={500} size={14}>
					Tip{tipCount}:
				</Text>{" "}
				{tip}
			</Text>
		</Flex>
	);
}
