import { useEffect, useState } from "react";
import NotificationsTable from "./components/notificationsComponents/NotificationsTable";
import { IUserNotificationResponse } from "./interfaces/INotification";

interface INotificationsProps {
	data: IUserNotificationResponse[];
	isSuccess: boolean;
	refetch: () => void;
}

export default function Notifications({ data, isSuccess, refetch }: INotificationsProps) {
	const [notifications, setNotifications] = useState<IUserNotificationResponse[]>([]);

	useEffect(() => {
		if (data && isSuccess) {
			setNotifications(data);
		}
	}, [data, isSuccess]);

	return (
		<div>
			<NotificationsTable refetch={refetch} notifications={notifications} />
		</div>
	);
}
