import { hideNotification } from "@mantine/notifications";
import {
	showNotificationError,
	showNotificationLoading,
	showNotificationSuccess,
} from "@reusable/Notifications/notificationSystem";
import { updateClient } from "@services/clients";
import { useMutation } from "@tanstack/react-query";
import { ClientUpdate } from "@views/orders/SmallerComponents/ClientEditDetails";
function useUpdateClient() {
	const randomId = Math.random().toString(36).substring(7);
	return useMutation<any, Error, ClientUpdate>(
		//@ts-ignore
		({ clientId, ...rest }: ClientUpdate) => updateClient(clientId, rest),
		{
			onSuccess: async () => {
				showNotificationSuccess("Clientul a fost actualizat cu succes!");
				hideNotification(randomId);
			},
			onError: async (error: string) => {
				showNotificationError("A aparut o eroare!");
				hideNotification(randomId);
			},
			onMutate: async () => {
				showNotificationLoading("Se proceseaza...", randomId);
			},
		},
		{ useErrorBoundary: true }
	);
}

export default useUpdateClient;
