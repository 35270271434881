import { CCard, CCardBody, CCardHeader, CCol, CRow } from "@coreui/react";
import { useIsMobileScreen } from "@hooks/functional/useIsMobileScreen";
import { useGetCampaignList } from "@hooks/notifications/useGetCampaignList";
import { ActionIcon, Badge, Card, Collapse, Title, createStyles } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import SendSmsPromotionsButton from "@reusable/Buttons/SendPromotionButton";
import utils from "@reusable/utils";
import { IconMessageForward } from "@tabler/icons";
import moment from "moment";
import { useCallback } from "react";
import { DisplayPhoneNumberList } from "./NewPromotion";
export interface ICampaignInterface {
	_id?: string;
	campaignName: string;
	phoneNumberList: string[];
	phoneNumberListLength: number;
	createdAt?: Date;
	updatedAt?: Date;
	deletedAt?: Date;
	textSmsMessage: string;
	organizationId: string;
	lastDayOfCampaign: Date;
}

export const useParentStylesPromotions = createStyles((theme) => ({
	cardContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "10px",
		padding: "16px 32px",
	},
	cardHeader: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		gap: "10px",
	},
	textBlue: {
		color: theme.colors.blue[5],
		fontFamily: "Lato, sans-serif",
		fontSize: "14px",
		fontWeight: 600,
		lineHeight: "24px",
		textAlign: "left",
	},
	dateValue: {
		color: theme.colors.blue[5],
		fontFamily: "Lato, sans-serif",
		fontSize: "10px",
		fontWeight: 600,
		lineHeight: "15px",
		textAlign: "left",
	},
	smsTextDisplay: {
		fontFamily: "Lato, sans-serif",
		fontSize: "16px",
		fontWeight: 400,
		lineHeight: "24px",
		textAlign: "left",
	},
	pageHeader: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		// next line when screen is small
	},
	pageHeaderTitle: {
		fontFamily: "Lato, sans-serif",
		fontSize: "20px",
		fontWeight: 600,
		lineHeight: "25px",
		textAlign: "left",
	},
	leftSideHeaderRow: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		gap: "10px",
	},
	leftSideHeaderColumn: {
		display: "flex",
		flexDirection: "column-reverse",
		// reverse items in column

		justifyContent: "flex-start",
		alignItems: "flex-start",
		gap: "10px",
	},
	textPageHeader: {
		fontFamily: "Lato, sans-serif",
		fontSize: "16px",
		fontWeight: 400,
		lineHeight: "24px",
		textAlign: "left",
	},
	spanTextHeader: {
		//underline
		textDecoration: "underline",
		color: theme.colors.blue[5],
	},
}));
const isCampaignExpired = (lastDayOfCampaign: Date) => {
	if (!lastDayOfCampaign) return false;
	const endOfDayCampaign = moment(lastDayOfCampaign).endOf("day");
	const now = moment();

	// Compare if current time (now) is after the last day of campaign end of day
	return now.isAfter(endOfDayCampaign);
};

export function CampaignCard({ campaign }: { campaign: ICampaignInterface }) {
	//card display from mantine
	const { classes } = useParentStylesPromotions();
	const [opened, { open, close }] = useDisclosure(false);
	return (
		<>
			<Card shadow="lg" radius="md" withBorder className={classes.cardContainer}>
				<div className={classes.cardHeader}>
					<ActionIcon
						size="md"
						color="blue.5"
						variant="filled"
						onClick={() => {
							opened ? close() : open();
						}}
					>
						<IconMessageForward size={19} />
					</ActionIcon>
					<div className={classes.textBlue}>{campaign.phoneNumberListLength} mesaje trimise</div>
					{isCampaignExpired(campaign.lastDayOfCampaign) && (
						<Badge color="gray" variant="filled">
							EXPIRATA
						</Badge>
					)}
				</div>
				<div>
					<Title order={5}>{campaign.campaignName} </Title>
					<div>
						<div className={classes.dateValue}>
							{utils.getDateFormat(campaign.createdAt)} -{" "}
							{utils.getDateFormat(campaign.lastDayOfCampaign, true)}
						</div>
					</div>
				</div>
				<div className={classes.smsTextDisplay}>{campaign.textSmsMessage}</div>

				<Collapse in={opened}>
					<DisplayPhoneNumberList phoneNumberList={campaign.phoneNumberList} />
				</Collapse>
			</Card>
		</>
	);
}
export default function PromotionList() {
	const { data } = useGetCampaignList();
	const { classes } = useParentStylesPromotions();
	const isMobile = useIsMobileScreen();
	const getCountMessages = useCallback(() => {
		if (data) {
			return data.reduce((acc: any, curr: any) => acc + curr.phoneNumberListLength, 0);
		}
		return 0;
	}, [data]);
	return (
		<div>
			<CRow>
				<CCol xs="12" lg="12">
					<CCard>
						<CCardHeader className={classes.pageHeader}>
							<div className={classes.pageHeaderTitle}>Lista campanii:</div>
							<div className={isMobile ? classes.leftSideHeaderColumn : classes.leftSideHeaderRow}>
								<div className={classes.textPageHeader}>
									Ati creat:{" "}
									<span className={classes.spanTextHeader}> {data?.length} campanii</span>
								</div>
								<div>
									<div className={classes.textPageHeader}>
										Ati trimis:{" "}
										<span className={classes.spanTextHeader}> {getCountMessages()} mesaje</span>
									</div>
								</div>
								<SendSmsPromotionsButton />
							</div>
						</CCardHeader>
						<CCardBody
							style={{
								display: "flex",
								flexDirection: "column",
								gap: "10px",
							}}
						>
							{data && data.length > 0 ? (
								data.map((campaign: ICampaignInterface) => (
									<CampaignCard key={campaign._id} campaign={campaign} />
								))
							) : (
								<div>Nu exista campanii</div>
							)}
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</div>
	);
}
