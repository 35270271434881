// @ts-ignore
import { reduce } from "lodash";
// @ts-ignore
import isEqual from "lodash/isEqual";


export default function checkObjectsDifference(
	initial: Record<string, any>,
	afterChanges: Record<string, any>
) {
	const diffKeysArray = reduce(
		{ ...initial, ...afterChanges }, // Include keys from both objects
		(result: string[], value: any, key: string) => {
			// Check if key is new in afterChanges or differs from initial
			return initial.hasOwnProperty(key) && isEqual(value, initial[key])
				? result
				: result.concat(key);
		},
		[]
	);

	// Set data to update only if there is a change
	if (diffKeysArray.length > 0) {
		// Construct dataToUpdateDiff using only differing keys
		const dataToUpdateDiff = reduce(
			afterChanges,
			(result: Record<string, any>, value: any, key: string) => {
				return diffKeysArray.includes(key) ? { ...result, [key]: value } : result;
			},
			{}
		);
		return {
			dataToUpdateDiff,
			diffKeysArray,
		};
	}
	return {
		dataToUpdateDiff: {},
		diffKeysArray: [],
	};
}

