import { Group, Select, Text } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { IOffer } from "../../interfaces/IOffer";
import { plansArray } from "../../utils/plans";
import PlanIcon from "./PlanIcon";

interface ItemProps {
	value: string;
	label: string;
	icon: string;
}

interface IPlanSelectProps {
	offerForm: UseFormReturnType<IOffer, (values: IOffer) => IOffer>;
}

export default function PlanSelect({ offerForm }: IPlanSelectProps) {
	const { t } = useTranslation();

	const selectPlan = (val: string) => {
		const foundPlan = plansArray(t).find((plan) => plan.value === val);
		if (foundPlan) {
			offerForm.setValues({
				// @ts-ignore
				features: { ...foundPlan.benefits, extra: [...offerForm.values.features?.extra] },
				name: foundPlan.title,
				price: foundPlan.price,
			});
		}
	};

	const selectData = plansArray(t).map((item, i) => ({
		label: item.title,
		value: item.value,
		icon: (
			<PlanIcon
				baseSize={{
					width: "16px",
					height: "10px",
				}}
				top={4}
				amount={i + 1}
			/>
		),
	}));

	const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
		({ icon, label, ...others }: ItemProps, selectItemRef) => (
			<div {...others} ref={selectItemRef}>
				<Group align="flex-start" noWrap>
					<div
						style={{
							scale: 0.5,
						}}
					>
						{icon}
					</div>

					<div>
						<Text>{label}</Text>
					</div>
				</Group>
			</div>
		)
	);

	return (
		<Select
			value={offerForm.values.name.toLowerCase()}
			w="100%"
			itemComponent={SelectItem}
			label="Plan"
			data={selectData}
			classNames={{
				input: "input-styles",
				item: "select-item",
			}}
			onChange={(val) => selectPlan(val || "")}
		/>
	);
}
