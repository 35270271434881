export interface ISmsNotfication {
	_id: string;
	organizationId: string;
	orderId: string;
	clientId: string;
	clothesOrderId: string;
	horecaOrderId: string;
	recipientPhoneNumber: string;
	textSms: string;
	creditsUsed: number;
	providerUsed: string;
	status: ESmsNotificationStatus;
	errorMessage: string;
	providerReturnedMessage: string;
	sentAt: string;
	deliveredAt: string;
	createdAt: string;
	providerId: string;
	imageUrl: string;
	deletedAt?: string | null;
}

export enum ESmsNotificationStatus {
	CREATED = "created",
	ERROR_SENDING = "error-sending",
	DELIVERED = "delivered",
}
