import { IExtraOption, IOffer } from "../interfaces/IOffer";

export const calculateOfferTotals = (values: IOffer) => {
	let total = values.price || 0;
	let extraTotal = 0;

	if ((values.features?.extra as IExtraOption[])?.length) {
		const tempExtraTotal =
			(values.features?.extra as IExtraOption[])?.reduce((a, b) => a + b.value, 0) ?? 0;
		extraTotal += tempExtraTotal;
        total += extraTotal;
	}


	return {
		total: total.toFixed(2),
		extraTotal: extraTotal.toFixed(2),
	};
};
