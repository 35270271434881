export const extraOrdersArray = (t: any) => {
	return [
		// {
		// 	label: "0 comenzi extra",
		// 	value: "0",
		// },
		{
			label: `100 ${t("admin.pdf.costs.extra.orders")} (200 ${t(
				"admin.pdf.costs.extra.smsIncluded"
			)})`,
			value: "5",
		},
		{
			label: `200 ${t("admin.pdf.costs.extra.orders")} (400 ${t(
				"admin.pdf.costs.extra.smsIncluded"
			)})`,
			value: "9",
		},
		{
			label: `500 ${t("admin.pdf.costs.extra.orders")} (1000 ${t(
				"admin.pdf.costs.extra.smsIncluded"
			)})`,
			value: "21.25",
		},
		{
			label: `1000 ${t("admin.pdf.costs.extra.orders")} (2000 ${t(
				"admin.pdf.costs.extra.smsIncluded"
			)})`,
			value: "34",
		},
	];
};

export const extraSMSArray = (t: any) => {
	return [
		// {
		// 	label: "0 SMS-uri extra",
		// 	value: "0",
		// },
		{
			label: `500 ${t("admin.pdf.costs.extra.extraSms")}`,
			value: "5",
		},
		{
			label: `1000 ${t("admin.pdf.costs.extra.extraSms")}`,
			value: "9",
		},
		{
			label: `2000 ${t("admin.pdf.costs.extra.extraSms")}`,
			value: "17.5",
		},
		{
			label: `5000 ${t("admin.pdf.costs.extra.extraSms")}`,
			value: "42.5",
		},
		{
			label: `10000 ${t("admin.pdf.costs.extra.extraSms")}`,
			value: "80",
		},
	];
};

export const supportOptions = (t: any) => {
	return [
		{
			value: "email",
			label: t("admin.pdf.support.email"),
		},
		{
			value: "email-whatsapp",
			label: t("admin.pdf.support.emailAndWhatsapp"),
		},
		{
			value: "email-whatsapp-phone",
			label: t("admin.pdf.support.emailAndWhatsappAndPhone"),
		},
	];
};
