import { Select } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { useSelector } from "react-redux";
import { OrganizationInterface } from "types";
import { IOffer } from "../../interfaces/IOffer";

interface IOrganizationSelectProps {
	offerForm: UseFormReturnType<IOffer, (values: IOffer) => IOffer>;
}

export default function OrganizationSelect({ offerForm }: IOrganizationSelectProps) {
	const organizationList = useSelector(
		// @ts-ignore
		(state) => state.form.organizationList
	) as OrganizationInterface[];

	const selectData = organizationList.map((item, i) => ({
		value: item._id as string,
		label: `${item.organizationNameEntity} - ${item.organizationCif}`,
	}));

	return (
		<Select
			{...offerForm.getInputProps("organizationId")}
			classNames={{
				input: "input-styles",
				item: "select-item",
			}}
			w="100%"
			searchable
			label="Organizatie"
			data={selectData}
		/>
	);
}
