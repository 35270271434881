import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { NotificationsProvider } from "@mantine/notifications";
import { SpotlightProvider } from "@mantine/spotlight";
import SearchComponentItem from "@reusable/SearchComponentItem";
import utils from "@reusable/utils";
import { IconSearch } from "@tabler/icons";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AdminPage from "@views/pages/register/AdminPage";
import React from "react";
import { I18nextProvider, useTranslation } from "react-i18next";
import { HashRouter, Route, Switch } from "react-router-dom";
import i18n from "./i18n";
import "./scss/style.scss";
const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
);
// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Docs = React.lazy(() => import("@views/pages/docs/docs"));
const TermAndConditions = React.lazy(() => import("@views/pages/Legal/TermsAndConditions"));
const CookiePolicy = React.lazy(() => import("@views/pages/Legal/CookiePolicy"));
const ConfidentialData = React.lazy(() => import("@views/pages/Legal/ConfidentialData"));
const queryClient = new QueryClient();
function App() {
	const { t } = useTranslation();
	return (
		<MantineProvider
			theme={{
				colorScheme: "light", // Or "dark" depending on your theme preference
				components: {
					TextInput: {
						styles: (theme) => ({
							input: {
								"&:disabled": {
									color: theme.colors.dark[9], // Set text color to colors.dark[9] when disabled
									backgroundColor: theme.colors.gray[2], // Set background color to ensure good contrast
									opacity: 1,
								},
							},
						}),
					},
					NumberInput: {
						styles: (theme) => ({
							input: {
								"&:disabled": {
									color: theme.colors.dark[9],
									backgroundColor: theme.colors.gray[2],
									opacity: 1,
								},
							},
						}),
					},
					Textarea: {
						styles: (theme) => ({
							input: {
								"&:disabled": {
									color: theme.colors.dark[9],
									backgroundColor: theme.colors.gray[2],
									opacity: 1,
								},
							},
						}),
					},
				},
			}}
		>
			<NotificationsProvider>
				<I18nextProvider i18n={i18n}>
					<QueryClientProvider client={queryClient}>
						<ModalsProvider>
							<SpotlightProvider
								highlightQuery
								centered
								cleanQueryOnClose
								actions={[]}
								searchIcon={<IconSearch size={18} />}
								searchPlaceholder={t("general.searchPlaceholder")}
								nothingFoundMessage={t("general.noOrdersFound")}
								shortcut="Ctrl + K"
								actionComponent={SearchComponentItem}
								styles={{
									...(utils.isMobile() && {
										inner: {
											paddingTop: 0,
										},
									}),
								}}
							>
								<HashRouter>
									<React.Suspense fallback={loading}>
										<Switch>
											<Route
												exact
												path="/terms-and-conditions"
												name="Termeni si conditii"
												component={TermAndConditions}
											/>
											<Route
												exact
												path="/cookie-policy"
												name="Politica de cookie"
												component={CookiePolicy}
											/>
											<Route
												exact
												path="/privacy-policy"
												name="Date confidentiale"
												component={ConfidentialData}
											/>

											<Route exact path="/docs" name="Documentatie" component={Docs} />

											<Route
												exact
												path="/login"
												name="Login Page"
												render={(props) => <Login {...props} />}
											/>
											<Route
												exact
												path="/admin"
												name="Admin page"
												render={(props) => <AdminPage {...props} />}
											/>
											<Route
												exact
												path="/404"
												name="Page 404"
												render={(props) => <Page404 {...props} />}
											/>
											<Route
												exact
												path="/500"
												name="Page 500"
												render={(props) => <Page500 {...props} />}
											/>
											<Route path="/" name="Home" render={(props) => <TheLayout {...props} />} />
										</Switch>
									</React.Suspense>
								</HashRouter>
							</SpotlightProvider>
						</ModalsProvider>
					</QueryClientProvider>
				</I18nextProvider>
			</NotificationsProvider>
		</MantineProvider>
	);
}

export default App;
