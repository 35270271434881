import { ActionIcon, Flex, Popover, Stack, Text, Tooltip } from "@mantine/core";
import { IconEye } from "@tabler/icons";
import { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { ClipboardCheck, ClipboardX } from "tabler-icons-react";
import { OrganizationInterface } from "types";
import { INotificationData } from "./AddEditNotificationModal";
import OrganizationMultiselect from "./OrganizationMultiselect";

interface IOrganizationsViewAndSelectProps {
	actionType: "ADD" | "EDIT";
	notificationData: INotificationData;
	setNotificationData: Dispatch<SetStateAction<INotificationData>>;
}

export default function OrganizationsViewAndSelect({
	actionType,
	notificationData,
	setNotificationData,
}: IOrganizationsViewAndSelectProps) {
	const organizationList = useSelector(
		// @ts-ignore
		(state) => state.form.organizationList
	) as OrganizationInterface[];

	const returnOrgaizationNames = () => {
		return organizationList.filter((item) =>
			// @ts-ignore
			notificationData.organizationIds.includes(item._id as string)
		);
	};

	const areAllSelected = notificationData.organizationIds.length === organizationList.length;

	const addAllOrganizations = () => {
		const tempIds = organizationList.map((item) => item._id) as string[];
		if (areAllSelected) {
			setNotificationData((prev) => ({
				...prev,
				organizationIds: [],
			}));
		} else {
			setNotificationData((prev) => ({
				...prev,
				organizationIds: tempIds,
			}));
		}
	};

	return (
		<Flex gap={16} align="flex-end">
			<OrganizationMultiselect
				readOnly={actionType === "EDIT"}
				onChange={(val) => {
					// @ts-ignore
					setNotificationData((prev) => ({
						...prev,
						organizationIds: val,
					}));
				}}
				value={notificationData.organizationIds}
			/>
			<Tooltip withArrow label={areAllSelected ? "Deselecteaza tot" : "Selecteaza tot"}>
				<ActionIcon
					onClick={addAllOrganizations}
					w="100%"
					style={{ border: "1px solid var(--divider)", borderRadius: 8 }}
					maw={48}
					h={48}
					variant="outline"
				>
					{areAllSelected ? (
						<ClipboardX color="var(--heading)" />
					) : (
						<ClipboardCheck color="var(--heading)" />
					)}
				</ActionIcon>
			</Tooltip>
			<Popover
				withArrow
				radius={8}
				styles={{
					dropdown: {
						zIndex: 3000,
						boxShadow: "var(--sh)",
						padding: 24,
						height: 400,
					},
				}}
				withinPortal={false}
				width={250}
			>
				<Popover.Target>
					<Tooltip withArrow label="Vezi organizatii">
						<ActionIcon
							w="100%"
							style={{ border: "1px solid var(--divider)", borderRadius: 8 }}
							maw={48}
							h={48}
							variant="outline"
						>
							<IconEye stroke={2} color="var(--heading)" />
						</ActionIcon>
					</Tooltip>
				</Popover.Target>
				<Popover.Dropdown>
					<Stack
						style={{
							height: "100%",
						}}
						spacing={4}
					>
						<Text mb={4} size={24} fw={600}>
							Organizatii
						</Text>
						<Stack
							style={{
								overflowY: "auto",
								height: "100%",
							}}
							spacing={8}
						>
							{returnOrgaizationNames().map((item, i) => (
								<Text key={i} color="var(--body)">
									{i + 1}. {item.organizationName}
								</Text>
							))}
						</Stack>
					</Stack>
				</Popover.Dropdown>
			</Popover>
		</Flex>
	);
}
