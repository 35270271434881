import { useGetOneClient } from "@hooks/clients/useGetOneClient";
import {
	ActionIcon,
	Badge,
	Button,
	Drawer,
	Loader,
	MantineNumberSize,
	ScrollArea,
	Text,
	Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { openConfirmModal } from "@mantine/modals";
import { showNotificationSuccess } from "@reusable/Notifications/notificationSystem";
import utils, { formatPhoneNumber } from "@reusable/utils";
import clientsApi from "@services/clients";
import { IconExternalLink, IconTrash, IconUserCircle } from "@tabler/icons";
import { CampaignCard, ICampaignInterface } from "@views/clients/PromotionList";
import { AddressDetailed } from "@views/clothes/ClothesTypes";
import { OrderComing } from "@views/edit/GeneralInformation";
import { cloneElement, ReactElement, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./ClientDetailsDrawer.css";
import { ClientEditDetails } from "./ClientEditDetails";
export interface ClientInterface {
	_id?: string;
	name: string;
	phone: string;
	address?: string;
	createdAt?: Date;
	updatedAt?: Date;
	latestOrderId?: Array<string>;
	addressDetailed?: AddressDetailed;
	organizationId: string;
	reperPoint?: string;
	comments?: string;
}
interface ClientInfoProps {
	client: ClientInterface;
	t: any;
	lastOrder?: string;
	ordersTotal?: number;
	ordersTotalNumber?: number;
	closeDrawer?: () => void;
	totalNumbersOfCanceledPickupOrDelivery?: number;
}

function ClientInfo({
	client,
	t,
	lastOrder,
	ordersTotal,
	ordersTotalNumber,
	closeDrawer,
	totalNumbersOfCanceledPickupOrDelivery,
}: ClientInfoProps) {
	// delete client and refresh
	const deleteClient = (id: string) => {
		clientsApi
			.deleteClient(id)
			.then(() => {
				showNotificationSuccess(t("general.clientDeletedSuccess"));
				//after 1 second refresh the page
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			})
			.catch((err) => console.log(err));
	};
	const openDeleteModal = (clientId: string) =>
		openConfirmModal({
			title: t("general.titleDeleteClient"),
			centered: true,
			children: <Text size="sm">{t("general.descriptionDeleteClient")}</Text>,
			labels: {
				confirm: t("general.buttonDeleteClient"),
				cancel: t("ordersTable.deleteConfirmationCancel"),
			},
			confirmProps: { color: "red" },
			onCancel: () => console.log("deletion aborted"),
			onConfirm: () => deleteClient(clientId),
			zIndex: 199999,
		});
	return (
		<div className="client-card">
			<div className="client-card-header">
				<h2 className="client-card-title">{t("general.clientDetails")}</h2>
				<div className="client-card-header-actions">
					<ClientEditDetails clientId={client?._id || ""} />
					<Tooltip
						label={t("general.deleteClientDisabledTooltip")}
						events={{ hover: true, focus: true, touch: false }}
						multiline
						width={150}
						withArrow
					>
						<ActionIcon
							disabled={!!ordersTotalNumber}
							color="red"
							variant="outline"
							size="lg"
							sx={{ "&[data-disabled]": { pointerEvents: "all" } }}
							onClick={() => {
								openDeleteModal(client?._id || "");
								closeDrawer && closeDrawer();
							}}
						>
							<IconTrash />
						</ActionIcon>
					</Tooltip>
				</div>
			</div>
			<div className="client-card-body">
				<ul className="client-details">
					<li>
						<strong>{t("general.clientName")}:</strong> {client.name}
					</li>
					<li>
						<strong>{t("general.clientAddress")}:</strong> {client.address}
					</li>
					<li>
						<strong>{t("general.clientPhone")}:</strong> {formatPhoneNumber(client.phone || "")}
					</li>
					<li>
						<strong>{t("general.createdAt")}:</strong> {utils.getDateFormat(client?.createdAt)}
					</li>
					<li>
						<strong>{t("general.updatedAt")}:</strong> {utils.getDateFormat(client?.updatedAt)}
					</li>
					{lastOrder && (
						<li>
							<strong>{t("general.lastOrderDate")}:</strong> {utils.getDateFormat(lastOrder)}
						</li>
					)}
					{!!ordersTotal && (
						<li>
							<strong>{t("general.ordersTotal")}:</strong> {ordersTotal} RON
						</li>
					)}
					{!!ordersTotalNumber && (
						<li>
							<strong>{t("general.ordersTotalNumber")}:</strong> {ordersTotalNumber}
						</li>
					)}

					<li
						style={{
							color: "red",
						}}
					>
						<strong
							style={{
								color: "red",
							}}
						>
							{t("editOrder.clientInfo.clientCanceledDeliveryOrPickupCount")}:
						</strong>{" "}
						<b>{totalNumbersOfCanceledPickupOrDelivery}</b>
					</li>
					<li>
						<strong>Comentarii:</strong> {client?.comments}
					</li>
				</ul>
			</div>
		</div>
	);
}
function ClientActiveCampaigns({ campaignList }: { campaignList: Array<ICampaignInterface> }) {
	return (
		<>
			<div className="client-card">
				<div className="client-card-header">
					<h2 className="client-card-title"> Lista campanii active</h2>
					<div className="client-card-header-actions">
						<Tooltip
							label="Mergi la lista de campanii"
							events={{ hover: true, focus: true, touch: false }}
							multiline
							width={150}
							withArrow
						>
							<ActionIcon
								color="blue.7"
								variant="filled"
								size="lg"
								sx={{ "&[data-disabled]": { pointerEvents: "all" } }}
								onClick={() => {
									// history.push("/campanii/lista-campanii"); new tab
									window.open(`/#/campanii/lista-campanii`, "_blank");
								}}
							>
								<IconExternalLink />
							</ActionIcon>
						</Tooltip>
					</div>
				</div>
				<div className="client-card-body">
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "10px",
						}}
					>
						{campaignList && campaignList.length > 0 ? (
							campaignList.map((campaign: ICampaignInterface) => (
								<CampaignCard key={campaign._id} campaign={campaign} />
							))
						) : (
							<div>Nu exista campanii</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
interface OrderProps {
	order: OrderComing;
	t: any;
	redirectToOrderPage: (orderId: string) => void;
	roundUnderOneSqm: boolean;
}

const calculateTotalSqm = (products: Array<any>, shouldRoundUnderOneSqm: boolean) => {
	return products.reduce((acc, product) => {
		if (product?.width && product?.length) {
			const area = product.width * product.length;
			// If area is under 1 sqm and shouldRoundUnderOneSqm is true, add 1 sqm instead of the actual area
			return shouldRoundUnderOneSqm && area < 1 ? acc + 1 : acc + area;
		}
		return acc;
	}, 0);
};
function calculateTotalCancel(order: OrderComing) {
	const totalCancel =
		(order?.clientCancelPickupCount || 0) + (order?.clientCancelDeliverCount || 0);
	return totalCancel;
}
function OrderItem({ order, t, redirectToOrderPage, roundUnderOneSqm }: OrderProps) {
	// Calculate total square meters by iterating through products
	// This function should be defined if it's not already in your utils
	const totalSqm = calculateTotalSqm(order.products, roundUnderOneSqm);
	const totalCancel = calculateTotalCancel(order);
	return (
		<div className="order-card">
			<div className="order-card-header">
				<h3 className="order-card-title">
					{t("general.ticketNumber")}: {order?.ticketNumber ? order.ticketNumber : "N/A"}
				</h3>
			</div>
			<div className="order-card-body">
				{/* Order Type */}
				<div className="order-info">
					<span className="order-label">{t("general.orderType")}:</span>
					<span className="order-value">
						{utils.getNewBadge(
							order.delivery ? t("general.route") : t("general.inHouse"),
							order?.isPickupPoint,
							order?.pickupPointName,
							t
						)}
					</span>
				</div>

				{/* Order Status */}
				<div className="order-info">
					<span className="order-label">{t("editOrder.orderStatus")}:</span>
					<Badge
						size="xs"
						variant="light"
						style={{
							wordBreak: "break-word",
							height: "40px",
							maxWidth: "100%",
							hyphens: "auto",
							whiteSpace: "normal",
						}}
						styles={{
							inner: {
								whiteSpace: "normal",
							},
						}}
					>
						{utils.statusDecriptorForTable(
							t,
							order.status,
							order.delivery,
							order?.pickUpDateAproximate,
							order?.pickUpDate
						)}
					</Badge>
				</div>
				{/* Name on order */}
				<div className="order-info">
					<span className="order-label">{t("ordersTable.nameOnOrder")}:</span>
					<span className="order-value">{order?.clientName}</span>
				</div>
				{/* cancel delivery/pickup */}
				{!!totalCancel && (
					<div className="order-info">
						<span className="order-label-attention">
							{t("editOrder.clientInfo.clientCanceledDeliveryOrPickupCount")}:
						</span>
						<span className="order-value-attention">{totalCancel}</span>
					</div>
				)}

				{/* Creation Date */}
				<div className="order-info">
					<span className="order-label">{t("ordersTable.mentions")}:</span>
					<span className="order-value">{order?.notes || order?.driverNotes}</span>
				</div>
				{/* Creation Date */}
				<div className="order-info">
					<span className="order-label">{t("ordersTable.createdAt")}:</span>
					<span className="order-value">{utils.getDateFormat(order?.createdAt)}</span>
				</div>

				{/* Delivery Date */}
				{!!order?.deliveryDate && (
					<div className="order-info">
						<span className="order-label">{t("ordersTable.deliveryDate")}:</span>
						<span className="order-value">{utils.getDateFormat(order?.deliveryDate)}</span>
					</div>
				)}

				{/* Total Price */}
				{!!order?.totalPrice && (
					<div className="order-info">
						<span className="order-label">{t("ordersTable.totalPrice")}:</span>
						<span className="order-value">{order.totalPrice} RON</span>
					</div>
				)}

				{/* Products Total Number */}
				{!!order?.productsTotalNumber && (
					<div className="order-info">
						<span className="order-label">{t("ordersTable.totalItemsPickedUp")}:</span>
						<span className="order-value">{order.productsTotalNumber}</span>
					</div>
				)}

				{/* Summary Products Total Number */}
				{!!order?.summaryProductsTotalNumber && (
					<div className="order-info">
						<span className="order-label">{t("products.productsSumar")}:</span>
						<span className="order-value">{order.summaryProductsTotalNumber}</span>
					</div>
				)}

				{/* Total Square Meters */}
				{!!totalSqm && (
					<div className="order-info">
						<span className="order-label">{t("products.sqmBig")}:</span>
						<span className="order-value">{totalSqm.toFixed(2)} sqm</span>
					</div>
				)}
			</div>
			<div className="order-card-footer">
				<Button
					leftIcon={<IconExternalLink />}
					fullWidth
					color="blue.5"
					onClick={() => redirectToOrderPage(order._id)}
				>
					{t("ordersTable.redirectToOrder")}
				</Button>
			</div>
		</div>
	);
}

interface OrderListProps {
	orders: Array<OrderComing>;
	t: any;
	roundUnderOneSqm: boolean;
}
const redirectToOrderPage = (orderId: string) => {
	// open in new tab also with path
	window.open(`/#/covoare/edit/${orderId}`, "_blank");
};
function OrderList({ orders, t, roundUnderOneSqm }: OrderListProps) {
	return (
		<div className="order-list">
			{orders.map((order) => (
				<OrderItem
					key={order._id}
					order={order}
					t={t}
					redirectToOrderPage={redirectToOrderPage}
					roundUnderOneSqm={roundUnderOneSqm}
				/>
			))}
		</div>
	);
}

const calculateTotalPrice = (orders: Array<OrderComing>) => {
	return orders.reduce((acc, order) => {
		return order?.totalPrice ? acc + order.totalPrice : acc;
	}, 0);
};
interface PropsDrawer {
	clientId: string;
	size?: MantineNumberSize;
	enableBlink?: boolean;
	customButton?: ReactNode;
}
export function ClientDetailsDrawer({
	clientId,
	size = "xl",
	enableBlink,
	customButton,
}: PropsDrawer) {
	const [opened, { open, close }] = useDisclosure(false);
	const { t } = useTranslation();
	const { data, isLoading } = useGetOneClient(clientId, opened);
	const [totalOrdersCancels, setTotalOrdersCancels] = useState(0);
	//@ts-ignore
	const { roundUnderOneSqm } = useSelector((state) => state.form.organizationSelected);
	useEffect(() => {
		if (data?.orderList) {
			const totalOrdersCancels = data.orderList.reduce((acc: number, order: OrderComing) => {
				return acc + calculateTotalCancel(order);
			}, 0);
			setTotalOrdersCancels(totalOrdersCancels);
		} else {
			setTotalOrdersCancels(0);
		}
	}, [data]);
	const [isActive, setIsActive] = useState(false);

	useEffect(() => {
		if (clientId && enableBlink) {
			setIsActive(true);
			setTimeout(() => setIsActive(false), 5000); // 3000ms = 3 seconds
		}
	}, [clientId, enableBlink]); // This effect runs every time clientId changes

	const customButtonConfig =
		customButton &&
		cloneElement(customButton as ReactElement<any>, {
			onClick: () => {
				open();
			},
		});

	return (
		<>
			{customButtonConfig ? (
				<Tooltip label={t("general.clientDetails")} withArrow>
					{customButtonConfig}
				</Tooltip>
			) : (
				<ActionIcon
					variant="filled"
					size={size}
					color="blue.8"
					disabled={!clientId}
					onClick={open}
					className={isActive ? "active-icon-client-detalis" : ""}
				>
					<IconUserCircle />
				</ActionIcon>
			)}

			<Drawer
				opened={opened}
				onClose={close}
				size="xl"
				padding="md"
				position="right"
				zIndex={10000}
				title={t("general.clientDetails")}
			>
				{isLoading && <Loader />}
				{data && data?.clientInfo && (
					<ScrollArea style={{ height: "90vh" }} type="auto" scrollbarSize={16}>
						<ClientInfo
							closeDrawer={close}
							client={data?.clientInfo}
							t={t}
							lastOrder={data?.orderList[0]?.createdAt || ""}
							ordersTotal={calculateTotalPrice(data?.orderList)}
							ordersTotalNumber={data?.orderList.length}
							totalNumbersOfCanceledPickupOrDelivery={totalOrdersCancels}
						/>
						<ClientActiveCampaigns campaignList={data?.campaignList || []} />

						<h2 className="drawer-section-title">{t("sidebar.orderList")}</h2>

						{data.orderList && data.orderList.length > 0 && (
							<OrderList orders={data.orderList} t={t} roundUnderOneSqm={roundUnderOneSqm} />
						)}
					</ScrollArea>
				)}

				{!data?.clientInfo && !isLoading && (
					<div className="no-client-found">
						<Text size="lg" weight={500}>
							{t("general.noClientDataAvailable")}
						</Text>
					</div>
				)}
			</Drawer>
		</>
	);
}
