import { Avatar, Badge } from "@mantine/core";
import { IconCheck, IconDots, IconX } from "@tabler/icons";
import { useTranslation } from "react-i18next";
import { ESmsNotificationStatus } from "../interfaces/ISmsNotifications";

interface ICustomBadgeProps {
	error?: string;
	pending?: string;
	success?: string;
}

interface ISmsStatusBadgeProps {
	status: ESmsNotificationStatus;
	customLabels?: ICustomBadgeProps;
	customColors?: ICustomBadgeProps;
}

const badgeStyles = ({
	t,
	customLabels,
	customColors,
}: {
	t: any;
	customLabels?: ICustomBadgeProps;
	customColors?: ICustomBadgeProps;
}) => {
	return [
		{
			color: customColors?.error || "var(--error)",
			label: customLabels?.error || t("smsNotifications.table.status.error"),
			value: ESmsNotificationStatus.ERROR_SENDING,
			icon: <IconX color="white" stroke={3} style={{ flexShrink: 0 }} size={14} />,
		},
		{
			color: customColors?.pending || "var(--pending)",
			label: customLabels?.pending || t("smsNotifications.table.status.created"),
			value: ESmsNotificationStatus.CREATED,
			icon: <IconDots color="white" stroke={3} style={{ flexShrink: 0 }} size={14} />,
		},
		{
			color: customColors?.success || "var(--success)",
			label: customLabels?.success || t("smsNotifications.table.status.sent"),
			value: ESmsNotificationStatus.DELIVERED,
			icon: <IconCheck color="white" stroke={3} style={{ flexShrink: 0 }} size={14} />,
		},
	];
};

export default function SmsStatusBadge({
	status,
	customLabels,
	customColors,
}: ISmsStatusBadgeProps) {
	const { t } = useTranslation();
	const returnStatus = badgeStyles({ t, customLabels, customColors }).find(
		(item) => item.value === status
	);

	return (
		<Badge
			leftSection={
				<Avatar
					radius={1000}
					variant="outline"
					size={14}
					style={{
						backgroundColor: returnStatus?.color,
					}}
					styles={{
						placeholder: {
							border: "none",
						},
					}}
				>
					{returnStatus?.icon}
				</Avatar>
			}
			bg="none"
			px="10px"
			py="14px"
			radius={8}
			style={{
				border: "1px solid var(--divider)",
				fontSize: 14,
				textTransform: "none",
				fontWeight: 500,
				color: "var(--heading)",
			}}
		>
			{returnStatus?.label}
		</Badge>
	);
}
