import { Accordion, Flex, Stack, Text } from "@mantine/core";
import { IconEye } from "@tabler/icons";
import moment from "moment";
import { useSelector } from "react-redux";
import { OrganizationInterface } from "types";
import { IAppNotificationOrganization } from "../../interfaces/INotification";

interface IOrganizationsAccordionProps {
	organizations: IAppNotificationOrganization[];
}

export default function OrganizationsAccordion({ organizations }: IOrganizationsAccordionProps) {
	const organizationList = useSelector(
		// @ts-ignore
		(state) => state.form.organizationList
	) as OrganizationInterface[];

	const returnOrganziation = (id: string) => {
		return organizationList.find((item) => item._id === id);
	};

	return (
		<Accordion
			styles={{
				content: {
					paddingInline: 24,
				},
				control: {
					paddingInline: 24,
				},
			}}
			multiple
			defaultValue={organizations.map((item) => item.organizationId)}
		>
			{organizations.map((org, i) => {
				return (
					<Accordion.Item key={i} value={org.organizationId}>
						<Accordion.Control>
							{returnOrganziation(org.organizationId)?.organizationName}
						</Accordion.Control>
						<Accordion.Panel>
							<Stack spacing={8}>
								{org.usersRead.map((user, index) => (
									<Flex gap={12} align="center" key={i}>
										<IconEye size={18} color={user.read ? "var(--success)" : "var(--divider)"} />
										<Stack spacing={2}>
											<Text size={16} color="var(--heading)">
												{user.userName} ({user.role})
											</Text>
											{user.read && (
												<Text size={14} color="var(--bodyLight)">
													{moment(user.readAt).format("DD.MM.YYYY, HH:mm")}
												</Text>
											)}
										</Stack>
									</Flex>
								))}
							</Stack>
						</Accordion.Panel>
					</Accordion.Item>
				);
			})}
		</Accordion>
	);
}
