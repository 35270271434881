import { UseFormReturnType } from "@mantine/form";
import moment from "moment";
import { RefObject, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IExtraOption, IOffer } from "../../interfaces/IOffer";
import { convertImageToBase64 } from "../../utils/base64Image";
import { calculateOfferTotals } from "../../utils/calculateOfferTotals";
import { supportOptions } from "../../utils/dataArrays";
import { plansArray } from "../../utils/plans";

interface IPDFPreviewProps {
	offerForm: UseFormReturnType<IOffer, (values: IOffer) => IOffer>;
	componentRef: RefObject<HTMLDivElement>;
}

export default function PDFPreview({ offerForm, componentRef }: IPDFPreviewProps) {
	const { t } = useTranslation();
	const [img, setImg] = useState<any>("");
	const features = offerForm.values.features;
	const support = supportOptions(t).find((item) => item.value === features.includedSupport);

	useEffect(() => {
		convertImageToBase64("/zarpet1.png").then((result) => setImg(result));
	}, []);

	return (
		<div id="pdf-preview">
			<style>
				{`
:root {
	/* --dark2: rgb(15, 34, 45); */

	--accent: #e9a708;
	--accentLight: rgba(233, 167, 8, 0.06);
	--accent80: rgb(233, 167, 8, 0.8);
	--accent20: rgb(233, 167, 8, 0.2);
	--accent10: rgb(233, 167, 8, 0.1);
	--bg: #fafafa;
	--bg10: rgba(250, 250, 250, 0.1);
	--elevation: #ffffff;
	--negative-elevation: #f6f7f9;
	--heading: #0f222d;
	--body: #333333;
	--divider: #dcdfe4;
	--divider40: rgba(220, 223, 228, 0.4);
	--divider20: rgba(220, 223, 228, 0.2);
	--grey: #adb5bd;
	--bodyLight: #667085;
	--error: #f04438;
	--error10: rgba(240, 68, 56, 0.1);
	--success: #15b79f;
	--pending: #06aed4;
	--purple: #7950f2;
	--lightBrown: #eae4d8;
	--orange: #eba278;
	--greenAccent: #738063;
	/* EFFECTS */
	--sh: 0px 0px 0px 0px rgba(0, 0, 0, 0.06), 0px 5px 22px 0px rgba(0, 0, 0, 0.04);
	--sh-accent: 0px 1px 85px 1px var(--accent20);
	--inset-sh: inset 0px 4px 12px -4px rgba(0, 0, 0, 0.25);
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
font-family: Arial, Helvetica, sans-serif;
}

    .a4-container {
	width: 794px;
	height: 1123px;
	background-color: white;
	margin: 0 auto;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	padding: 0.3in 20mm 0.3in 20mm;
     }

	 .pdf-table {
	 border-collapse: collapse;
	 }

.pdf-table  td {
	font-size: 12px;
	padding: 4px 16px;
	color: #0f222d;
}

.pdf-table tr {
	border-bottom: 1px solid #eba278;
}

.pricing-table {
	color: #0f222d;
	table-layout: fixed;
	width: 100%;
	border-collapse: collapse;
}

.pricing-table td {
	font-size: 12px;
	color: #0f222d;
	border: 1px solid #dcdfe4;
	padding: 4px 16px;
	margin: 0;
}

.bold-row td {
	font-size: 14px;
	font-weight: bold;
	margin: 0;
}

.pricing-table td:last-child {
	text-align: right;
}

.pdf-table tr:last-child {
	border-bottom: none;
}

.plans-table {
	border-collapse: collapse;
	color: #0f222d;
	width: 100%;
}

.plans-table tr {
background-color: #eae4d8;
}

.plans-table td {
	font-size: 10px;
	padding: 4px 4px;
	word-wrap: break-word;
	border: 1px solid #738063;
	margin: 0;
}

.plans-table th {
	font-size: 10px;
	padding: 4px 4px;
	word-wrap: break-word;
	border: 1px solid #738063;
	margin: 0;
}

.stack {
display: flex;
flex-direction: column;
gap: 24px;
width: 100%
}

p {
    margin: 0;
}

`}
			</style>
			<div ref={componentRef} className="stack">
				<div
					className="stack"
					style={{
						gap: 0,
					}}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							backgroundColor: "#eae4d8",
							padding: 16,
							width: "100%",
						}}
					>
						<img
							style={{
								width: "30%",
							}}
							src={img}
							alt="Zarpet logo"
						/>
					</div>

					<div
						style={{
							padding: 12,
							backgroundColor: "#0f222d",
						}}
					>
						<p
							style={{
								textAlign: "center",
								fontSize: 14,
								fontWeight: 600,
								color: "var(--bg)",
							}}
						>
							{t("admin.pdf.title")}
						</p>
					</div>
				</div>
				<div
					style={{
						display: "flex",
					}}
				>
					<div
						className="stack"
						style={{
							gap: 8,
						}}
					>
						<div
							style={{
								backgroundColor: "#eae4d8",
								padding: 8,
							}}
						>
							<p
								style={{
									color: "#0f222d",
									fontSize: 16,
									fontWeight: 400,
									width: "100%",
								}}
							>
								{t("admin.pdf.sides.client")}:
							</p>
						</div>
						<div>
							<p
								style={{
									color: "#0f222d",
									fontSize: 14,
									fontWeight: 700,
									width: "100%",
								}}
							>
								{offerForm.values.companyName}
							</p>
							<p
								style={{
									color: "#0f222d",
									fontSize: 12,
									fontWeight: 400,
								}}
							>
								{offerForm.values.companyAdministratorName}
							</p>
							<p
								style={{
									color: "#0f222d",
									fontSize: 12,
									fontWeight: 400,
								}}
							>
								{offerForm.values.companyCUI}
							</p>
							<p
								style={{
									color: "#0f222d",
									fontSize: 12,
									fontWeight: 400,
								}}
							>
								{offerForm.values.companyAddress}
							</p>
							<p
								style={{
									color: "#0f222d",
									fontSize: 12,
									fontWeight: 400,
								}}
							>
								{offerForm.values.companyEmail}
							</p>
							<p
								style={{
									color: "#0f222d",
									fontSize: 12,
									fontWeight: 400,
								}}
							>
								{offerForm.values.companyPhone}
							</p>
						</div>
					</div>
					<div
						className="stack"
						style={{
							gap: 8,
						}}
					>
						<div
							style={{
								backgroundColor: "#eae4d8",
								padding: 8,
								paddingLeft: 0,
							}}
						>
							<p
								style={{
									color: "#0f222d",
									fontSize: 16,
									fontWeight: 400,
									width: "100%",
								}}
							>
								{t("admin.pdf.sides.company")}:
							</p>
						</div>
						<div>
							<p
								style={{
									color: "#0f222d",
									fontSize: 14,
									fontWeight: 700,
									width: "100%",
								}}
							>
								SoftPeak Digital SRL
							</p>
							<p
								style={{
									color: "#0f222d",
									fontSize: 12,
									fontWeight: 400,
								}}
							>
								Jud. Maramureş, Sat Groşi, Comuna Groşi, Strada 1 Decembrie, Nr. 55
							</p>
							<p
								style={{
									color: "#0f222d",
									fontSize: 12,
									fontWeight: 400,
								}}
							>
								contact@zarpet.net
							</p>
							<p
								style={{
									color: "#0f222d",
									fontSize: 12,
									fontWeight: 400,
								}}
							>
								0744 261 435
							</p>
						</div>
					</div>
				</div>

				<div
					className="stack"
					style={{
						gap: 8,
					}}
				>
					<div
						style={{
							backgroundColor: "#eae4d8",
							padding: 8,
						}}
					>
						<p
							style={{
								color: "#0f222d",
								fontSize: 16,
								fontWeight: 700,
								width: "100%",
							}}
						>
							{t("admin.pdf.offer")}
						</p>
					</div>
					<table className="pdf-table">
						<tbody>
							<tr>
								<td
									style={{
										fontWeight: 700,
									}}
								>
									{t("admin.pdf.offerId")}
								</td>
								<td>{offerForm.values.offerShortId}</td>
							</tr>
							<tr>
								<td
									style={{
										fontWeight: 700,
									}}
								>
									{t("products.product")}
								</td>
								<td>{t("admin.pdf.app")}</td>
							</tr>
							<tr>
								<td
									style={{
										fontWeight: 700,
									}}
								>
									{t("admin.pdf.planLabel")}
								</td>
								<td>{offerForm.values.name}</td>
							</tr>
							<tr>
								<td
									style={{
										fontWeight: 700,
									}}
								>
									{t("products.priceProduct")}
								</td>
								<td>
									{calculateOfferTotals(offerForm.values).total || 0} {t("admin.pdf.monthlyPrice")}
								</td>
							</tr>
							<tr>
								<td
									style={{
										fontWeight: 700,
									}}
								>
									{t("admin.pdf.subStart")}
								</td>
								<td>{moment(offerForm.values.startSubscription).format("DD/MM/YYYY")}</td>
							</tr>
							<tr>
								<td
									style={{
										fontWeight: 700,
									}}
								>
									{t("admin.pdf.invoicePeriod")}
								</td>
								<td>
									{offerForm.values?.invoicePeriod || 1} {t("admin.pdf.invoicePeriodMonths")}
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div
					className="stack"
					style={{
						gap: 8,
					}}
				>
					<div
						style={{
							backgroundColor: "#eae4d8",
							padding: 8,
						}}
					>
						<p
							style={{
								color: "#0f222d",
								fontSize: 16,
								fontWeight: 400,
								width: "100%",
							}}
						>
							{t("admin.pdf.plan", { plan: offerForm.values.name })}
						</p>
					</div>
					<table className="pricing-table">
						<tbody>
							<tr className="bold-row">
								<td>{t("admin.pdf.costs.included.title")}</td>
								<td>{`${offerForm.values.price.toFixed(2) || 0} ${t(
									"admin.pdf.monthlyPrice"
								)}`}</td>
							</tr>

							<tr hidden={!features.includedStores}>
								<td>
									{features.includedStores > 1
										? t("admin.pdf.costs.included.store.pl", {
												stores: features.includedStores,
										  })
										: t("admin.pdf.costs.included.store.sg")}
								</td>
								<td>{t("admin.pdf.plans.included")}</td>
							</tr>
							<tr hidden={!features.includedDrivers}>
								<td>
									{features.includedDrivers > 1
										? t("admin.pdf.costs.included.driver.pl", {
												drivers: features.includedDrivers,
										  })
										: t("admin.pdf.costs.included.driver.sg")}
								</td>
								<td>{t("admin.pdf.plans.included")}</td>
							</tr>
							<tr hidden={!features.includedOrders}>
								<td>{`${features.includedOrders} ${t("admin.pdf.ordersPerMonth")}`}</td>
								<td>{t("admin.pdf.plans.included")}</td>
							</tr>
							<tr hidden={!features.includedSMS}>
								<td>{`${features.includedSMS} ${t("admin.pdf.smsPerMonth")}`}</td>
								<td>{t("admin.pdf.plans.included")}</td>
							</tr>
							<tr hidden={!features.googleMaps}>
								<td>{t("admin.pdf.costs.included.googleMaps")}</td>
								<td>{t("admin.pdf.plans.included")}</td>
							</tr>
							<tr hidden={!features.includedSupport}>
								<td>{support?.label || ""}</td>
								<td>{t("admin.pdf.plans.included")}</td>
							</tr>
							<tr className="bold-row">
								<td>{t("admin.pdf.costs.extra.title")}</td>
								<td>
									{calculateOfferTotals(offerForm.values).extraTotal || 0}{" "}
									{t("admin.pdf.monthlyPrice")}
								</td>
							</tr>
							{(features.extra as IExtraOption[]).map((item, i) => (
								<tr key={i} hidden={!features.extra.length}>
									<td>{item.label}</td>
									<td>
										{item.value.toFixed(2) || 0} {t("admin.pdf.monthlyPrice")}
									</td>
								</tr>
							))}
							<tr className="bold-row">
								<td
									style={{
										fontSize: 16,
									}}
								>
									{t("admin.pdf.costs.total")}
								</td>
								<td
									style={{
										fontSize: 16,
									}}
								>{`${calculateOfferTotals(offerForm.values).total || 0} ${t(
									"admin.pdf.monthlyPrice"
								)}`}</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div
					className="stack"
					style={{
						gap: 8,
					}}
				>
					<div
						style={{
							backgroundColor: "#eae4d8",
							padding: 8,
						}}
					>
						<p
							style={{
								color: "#0f222d",
								fontSize: 16,
								fontWeight: 400,
								width: "100%",
							}}
						>
							{t("admin.pdf.plans.plans")}
						</p>
					</div>
					<table className="plans-table">
						<thead>
							<tr>
								<th>{t("admin.pdf.plans.headers.plan")}</th>
								<th>{t("products.priceProduct")}*</th>
								<th>{t("admin.pdf.plans.headers.maxOrders")}**</th>
								<th>{t("admin.pdf.plans.headers.drivers")}</th>
								<th>Google Maps</th>
								<th>{t("admin.pdf.costs.extra.smsIncluded")}</th>
								<th>{t("admin.pdf.plans.headers.additionalDriver")}***</th>
								<th>{t("admin.pdf.plans.headers.additionalStore")}****</th>
								<th>{t("admin.pdf.plans.headers.support")}</th>
							</tr>
						</thead>
						<tbody>
							{plansArray(t).map((plan, i) => (
								<tr key={i}>
									<td
										style={{
											fontWeight: 600,
										}}
									>
										{plan.title}
									</td>
									<td>{plan.price.toFixed(2)}</td>
									{plan.tableBenefits.map((benefit, index) => (
										<td key={index}>{benefit}</td>
									))}
								</tr>
							))}
							<tr>
								<td
									style={{
										fontWeight: 600,
									}}
								>
									Enterprise
								</td>
								<td>{t("admin.pdf.plans.custom")}</td>
								<td>{t("admin.pdf.plans.unlimited")}</td>
								<td>{t("admin.pdf.plans.custom")}</td>
								<td>{t("admin.pdf.plans.included")}</td>
								<td>{t("admin.pdf.plans.custom")}</td>
								<td>{t("admin.pdf.plans.custom")}</td>
								<td>{t("admin.pdf.plans.custom")}</td>
								<td>{t("admin.pdf.plans.fullyCustom")}</td>
							</tr>
						</tbody>
					</table>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							width: "100%",
						}}
					>
						<div
							className="stack"
							style={{
								gap: 8,
								width: "auto",
							}}
						>
							<p
								style={{
									fontSize: 12,
									color: "#333333",
								}}
							>
								*{t("admin.pdf.plans.plansInfo.price")}
							</p>
							<p
								style={{
									fontSize: 12,
									color: "#333333",
								}}
							>
								**{t("admin.pdf.plans.plansInfo.orders")}
							</p>
						</div>
						<div
							className="stack"
							style={{
								gap: 8,
								width: "auto",
							}}
						>
							<p
								style={{
									fontSize: 12,
									color: "#333333",
								}}
							>
								***{t("admin.pdf.plans.plansInfo.driver")}
							</p>
							<p
								style={{
									fontSize: 12,
									color: "#333333",
								}}
							>
								****{t("admin.pdf.plans.plansInfo.store")}
							</p>
							<p
								style={{
									fontSize: 12,
									color: "#333333",
								}}
							>
								****{t("admin.pdf.plans.plansInfo.discount")}
							</p>
						</div>
					</div>
				</div>

				<div
					className="stack"
					style={{
						gap: 8,
					}}
				>
					<div
						style={{
							backgroundColor: "#eae4d8",
							padding: 8,
						}}
					>
						<p
							style={{
								color: "#0f222d",
								fontSize: 16,
								fontWeight: 400,
								width: "100%",
							}}
						>
							{t("admin.pdf.info.title")}
						</p>
					</div>
					<div
						className="stack"
						style={{
							gap: 12,
						}}
					>
						<p
							style={{
								fontSize: 12,
								color: "#333333",
							}}
						>
							{t("admin.pdf.info.p1")}
						</p>
						<p
							style={{
								fontSize: 12,
								color: "#333333",
							}}
						>
							{t("admin.pdf.info.p2")}
						</p>
					</div>
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "flex-end",
						width: "100%",
						marginTop: "auto",
					}}
				>
					<div
						className="stack"
						style={{
							gap: 8,
							width: "50%",
						}}
					>
						<p
							style={{
								fontSize: 16,
								color: "#0f222d",
								// marginBottom: 8,
							}}
						>
							{t("admin.pdf.sides.company")}
						</p>
						<p
							style={{
								fontSize: 12,
								color: "#0f222d",
								paddingLeft: 16,
								borderBottom: "1px solid #0f222d",
								paddingBottom: 8,
							}}
						>
							Paul-Gabriel Filipan, Soft Peak Digital SRL
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
