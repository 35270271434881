import { Avatar, Button, Flex, Modal, Stack, Text, Title } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconAlertTriangle } from "@tabler/icons";
import { useTranslation } from "react-i18next";

interface IWarningModalProps {
	onCancel: () => void;
	onConfirm: () => void;
	title?: string;
	description: string;
	isOpened: boolean;
	confirmButtonLabel?: string;
}

export default function WarningModal({
	onCancel,
	onConfirm,
	title,
	description,
	isOpened,
	confirmButtonLabel,
}: IWarningModalProps) {
	const matches = useMediaQuery("(max-width: 768px)");
	const { t } = useTranslation();
	const newTitle = title || t("settings.v2.warning");
	const newConfirmButtonLabel = confirmButtonLabel || t("ordersTable.buttonConfirm");
	return (
		<Modal
			styles={{
				modal: {
					width: "100%",
					maxWidth: "500px",
				},
			}}
			withCloseButton={false}
			zIndex={2000}
			radius={20}
			opened={isOpened}
			onClose={onCancel}
		>
			<Stack spacing={16}>
				<Flex gap={12} align="center">
					<Avatar
						styles={{
							placeholder: {
								backgroundColor: "rgba(222, 113, 1, 0.1)",
							},
						}}
						radius={1000}
						size={52}
						color="rgba(222, 113, 1, 0.1)"
					>
						<IconAlertTriangle size={32} stroke={2} color="#DE7101" />
					</Avatar>
					<Title color="var(--heading)" fw={600} order={4}>
						{newTitle}
					</Title>
				</Flex>
				<Text color="var(--body)" size={16}>
					{description}
				</Text>
				<Flex
					wrap={matches ? "wrap" : "nowrap"}
					align="center"
					justify="flex-end"
					gap={16}
					w="100%"
				>
					<Button
						onClick={onCancel}
						type="button"
						w={matches ? "100%" : "auto"}
						classNames={{
							root: "button-style",
						}}
						color="dark"
						variant="subtle"
					>
						{t("registry.buttonNo")}
					</Button>
					<Button
						onClick={onConfirm}
						type="button"
						w={matches ? "100%" : "auto"}
						classNames={{
							root: "button-style",
						}}
						style={{ backgroundColor: "#DE7101" }}
					>
						{newConfirmButtonLabel}
					</Button>
				</Flex>
			</Stack>
		</Modal>
	);
}
