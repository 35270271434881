import { Stack, Text } from "@mantine/core";

interface ITitleAndDescriptionProps {
	title: string;
	description: string;
	reverse?: boolean;
	descriptionSize?: number
	gap?: number
}

export default function TitleAndDescription({
	title,
	description,
	reverse,
	descriptionSize = 14,
	gap = 4
}: ITitleAndDescriptionProps) {
	return reverse ? (
		<Stack spacing={gap}>
			<Text size={14} color="var(--bodyLight)">
				{title}
			</Text>
			<Text size={descriptionSize} color="var(--body)">
				{description}
			</Text>
		</Stack>
	) : (
		<Stack spacing={4}>
			<Text size={14} color="var(--body)">
				{title}
			</Text>
			<Text size={14} color="var(--bodyLight)">
				{description}
			</Text>
		</Stack>
	);
}
