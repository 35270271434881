import { Stack } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { RefObject } from "react";
import { IOffer } from "../../interfaces/IOffer";
import "../../style/offerPrint.css";
import PDFPreview from "./PDFPreview";

interface IPdfContainerProps {
	offerForm: UseFormReturnType<IOffer, (values: IOffer) => IOffer>;
	componentRef: RefObject<HTMLDivElement>;
}

export default function PdfContainer({ offerForm, componentRef }: IPdfContainerProps) {
	return (
		<div>
			<Stack spacing={40}>
				<div
					className="a4-container"
					style={{
						height: "fit-content",
					}}
				>
					<PDFPreview componentRef={componentRef} offerForm={offerForm} />
				</div>
			</Stack>
		</div>
	);
}
