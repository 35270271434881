import { Center, Loader } from "@mantine/core";
import { ReactNode } from "react";

interface IDotsLoaderProps {
	isLoading: boolean;
	children: ReactNode;
}

export default function DotsLoader({ children, isLoading }: IDotsLoaderProps) {
	return isLoading ? (
		<Center h="50vh">
			<Loader size={100} color="yellow" variant="dots" />
		</Center>
	) : (
		<div>{children}</div>
	);
}
