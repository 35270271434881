import { Flex, Stack, Text } from "@mantine/core";
import { ReactNode } from "react";

interface IActivePageBreadCrumbsProps {
	pageTitle: string;
	breadCrumbs: (string | ReactNode)[];
}

export default function ActivePageBreadCrumbs({
	breadCrumbs,
	pageTitle,
}: IActivePageBreadCrumbsProps) {
	return (
		<Stack spacing={0}>
			<Text color="var(--heading)" fw={700} size={32}>
				{pageTitle}
			</Text>
			<Flex align="center" gap={4}>
				{breadCrumbs.map((item, i) =>
					typeof item === "string" ? (
						<Text size={16} color="var(--bodyLight)">
							{item} {i !== breadCrumbs.length - 1 && "/"}
						</Text>
					) : (
						item
					)
				)}
			</Flex>
		</Stack>
	);
}
