import clientsApi from "@services/clients";
import { useQuery } from "@tanstack/react-query";

export const useGetOneClient = (clientId: string, opened: boolean) => {
	return useQuery(
		["client-details-" + clientId + opened],
		() => {
			if (clientId && opened) {
				return clientsApi.getOneClient(clientId);
			} else {
				return null;
			}
		},
		{ retry: false }
	);
};
