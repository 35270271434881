import { MultiSelect } from "@mantine/core";
import { useSelector } from "react-redux";
import { OrganizationInterface } from "types";

interface IOrganizationMultiselectProps {
	onChange: (val: string[]) => void;
	value: string[];
	readOnly?: boolean;
	isDisabled?: boolean;
}

export default function OrganizationMultiselect({
	onChange,
	value,
	isDisabled,
	readOnly,
}: IOrganizationMultiselectProps) {
	const organizationList = useSelector(
		// @ts-ignore
		(state) => state.form.organizationList
	) as OrganizationInterface[];

	const selectData = organizationList.map((item, i) => ({
		value: item._id as string,
		label: `${item.organizationNameEntity} - ${item.organizationCif}`,
	}));

	return (
		<MultiSelect
			readOnly={readOnly}
			disabled={isDisabled}
			classNames={{
				input: "input-styles",
				item: "select-item",
			}}
			w="100%"
			searchable
			label="Organizatie"
			data={selectData}
			onChange={(val) => onChange(val)}
			value={value}
		/>
	);
}
