export const plansArray = (t: any) => {
	return [
		{
			value: "basic",
			title: "Basic",
			price: 33.3,
			benefits: {
				includedSupport: "email",
				googleMaps: false,
				includedOrders: 300,
				includedSMS: 1000,
				includedStores: 1,
				includedDrivers: 0,
			},
			tableBenefits: [
				"300",
				"-",
				"-",
				"1000",
				"-",
				"+10€",
				t("admin.plans.basic.benefits.support"),
			],
		},
		{
			value: "lite",
			title: "Lite",
			price: 49.99,
			benefits: {
				googleMaps: false,
				includedSupport: "email-whatsapp",
				includedOrders: 500,
				includedSMS: 1000,
				includedStores: 1,
				includedDrivers: 1,
			},
			tableBenefits: [
				"500",
				t("admin.pdf.plans.unlimited"),
				"-",
				"1000",
				"0",
				"+10€",
				t("admin.plans.lite.benefits.support"),
			],
		},
		{
			value: "grow",
			title: "Grow",
			price: 59.99,
			benefits: {
				googleMaps: true,
				includedSupport: "email-whatsapp-phone",
				includedOrders: 600,
				includedSMS: 2000,
				includedStores: 1,
				includedDrivers: 1,
			},
			tableBenefits: [
				"600",
				"1",
				t("admin.pdf.plans.included"),
				"2000",
				"+5€",
				"+10€",
				t("admin.plans.grow.benefits.support"),
			],
		},
	];
};
