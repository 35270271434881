import { CDataTable } from "@coreui/react";
import { Checkbox, LoadingOverlay } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import CardWrapper from "@views/pages/settingsV2/components/CardWrapper";
import { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import "../styles/dataTable.css";
import TableButtonsContainer from "../tableWrapper/TableButtonsContainer";
import {
	checkboxState,
	selectData,
	selectOrDeselectAllData,
} from "../tableWrapper/tableWrapperUtils";
import { IColumnModel } from "./DataTableWrapper";

interface IDataTableWrapperProps<TData> {
	data: TData[];
	columns: IColumnModel<TData>[];
	withPagination?: boolean;
	withSorter?: boolean;
	withFilters?: boolean;
	storeKey: string;
	refetch?: () => void;
	contentAboveTable?: ReactNode;
	isLoading?: boolean;
	selectKey?: keyof TData;
	selectedData?: string[];
	setSelectedData?: (val: any) => void;
	/**
	 * Enables the selection column in the table.
	 *
	 * **Recommended Value:** `true` if you want to enable row selection.
	 *
	 * ### Required Fields when `withSelectColumn` is `true`:
	 * - **`selectKey`**: The key in your data object used for identifying rows. **`(e.g. id)`**
	 * - **`selectedData`**: An array containing the [selectKey] of the selected rows.
	 * - **`setSelectedData`**: A callback function to update the selected rows.
	 *
	 * @note These fields are mandatory only when `withSelectColumn` is set to `true`.
	 * @default false
	 */
	withSelectColumn?: boolean;
	itemsPerPage?: number;
	withColumnToggler?: boolean;
	style?: {
		cardStyle?: CSSProperties;
	};
	withCellStyling?: {
		condition: (item: TData, i?: number) => boolean;
		stylesIfTrue: CSSProperties;
		stylesIfFalse: CSSProperties;
	};
	searchPlaceholder?: string;
	excludedColumns?: string[];
}

export default function DataTableWrapperSimple<TData>({
	columns,
	data,
	withFilters = true,
	withPagination = true,
	withSorter = true,
	storeKey,
	refetch,
	contentAboveTable,
	isLoading,
	selectKey,
	selectedData,
	setSelectedData,
	withSelectColumn,
	itemsPerPage = 50,
	withColumnToggler = true,
	style,
	withCellStyling,
	excludedColumns,
	searchPlaceholder,
}: IDataTableWrapperProps<TData>) {
	const { t } = useTranslation();
	const [isPortalReady, setIsPortalReady] = useState(false);
	const tableContainerRef = useRef<HTMLDivElement | null>(null);
	const newSearchPlaceholder = searchPlaceholder || t("smsNotifications.table.searchPlaceholder");

	const columnHasAllProps = () => {
		if (withSelectColumn && selectedData && selectKey && setSelectedData) return true;
		else return false;
	};

	const isChecked = useCallback(
		(item: TData) => {
			if (selectKey && selectedData?.includes(item[selectKey] as string)) return true;
			else return false;
		},
		[selectKey, selectedData]
	);

	const handleSelectData = useCallback(
		(item: TData) => {
			if (selectKey) selectData(item[selectKey] as string, selectedData, setSelectedData);
		},
		[selectKey, selectedData]
	);

	const returnColumns = useMemo(() => {
		const tempColumns = [
			...columns.filter((item) => !excludedColumns?.includes(item.key as string)),
		];

		if (columnHasAllProps() && !tempColumns.some((col) => col.key === "selectField")) {
			tempColumns.unshift({
				// @ts-ignore
				key: "selectField",
				label: (
					<Checkbox
						color="yellow"
						indeterminate={checkboxState("intermediate", data, selectedData)}
						onChange={() =>
							selectOrDeselectAllData(
								data,
								selectKey as string | undefined,
								selectedData,
								setSelectedData
							)
						}
						checked={checkboxState("checked", data, selectedData)}
					/>
				),
				filter: false,
				sorter: false,
				content: (item) => {
					return (
						<td>
							<Checkbox
								color="yellow"
								checked={isChecked(item)}
								onChange={() => handleSelectData(item)}
							/>
						</td>
					);
				},
			});
		}

		return tempColumns.map((col) => ({
			...col,
			content: (item: TData, i: number) => {
				return (
					<td
						style={
							withCellStyling?.condition(item, i)
								? withCellStyling.stylesIfTrue
								: withCellStyling?.stylesIfFalse
						}
					>
						{col.content ? col?.content(item, i) : (item[col.key] as ReactNode)}
					</td>
				);
			},
		}));
	}, [columns, data, selectedData, selectKey, isChecked, excludedColumns]);

	// const returnColumns1 = () => {
	// 	const tempColumns = [...columns];
	// 	if (columnHasAllProps() && !tempColumns.some((col) => col.key === "selectField")) {
	// 		tempColumns.unshift({
	// 			// @ts-ignore
	// 			key: "selectField",
	// 			label: (
	// 				<Checkbox
	// 					color="yellow"
	// 					indeterminate={checkboxState("intermediate", data, selectedData)}
	// 					onChange={() =>
	// 						selectOrDeselectAllData(
	// 							data,
	// 							selectKey as string | undefined,
	// 							selectedData,
	// 							setSelectedData
	// 						)
	// 					}
	// 					checked={checkboxState("checked", data, selectedData)}
	// 				/>
	// 			),
	// 			filter: false,
	// 			sorter: false,
	// 			content: (item) => {
	// 				return (
	// 					<td>
	// 						<Checkbox
	// 							color="yellow"
	// 							checked={isChecked(item)}
	// 							onChange={() => handleSelectData(item)}
	// 						/>
	// 					</td>
	// 				);
	// 			},
	// 		});
	// 	}
	// 	return tempColumns.map((col) => ({
	// 		...col,
	// 		content: (item: TData, i: number) => {
	// 			return (
	// 				<td
	// 					style={
	// 						withCellStyling?.condition(item, i)
	// 							? withCellStyling.stylesIfTrue
	// 							: withCellStyling?.stylesIfFalse
	// 					}
	// 				>
	// 					{col.content ? col?.content(item, i) : (item[col.key] as ReactNode)}
	// 				</td>
	// 			);
	// 		},
	// 	}));
	// };

	const [displayColumns, setDisplayColumns, removeItem] = useLocalStorage<string[]>({
		key: storeKey,
		defaultValue: returnColumns.map((col) => col.key as string),
	});

	const scopedSlots = returnColumns.reduce((acc, item) => {
		if (item.content) {
			// @ts-ignore
			acc[item.key] = item.content;
		}
		return acc;
	}, {} as Record<string, any>);

	useEffect(() => {
		if (tableContainerRef.current && withColumnToggler) {
			const targetElement = tableContainerRef.current.querySelector(
				".col-sm-6.form-inline.p-0.c-datatable-filter"
			);
			if (targetElement && !targetElement.querySelector(".newCol")) {
				setIsPortalReady(true);
			}
		}
	}, []);

	const filteredColumns = useMemo(() => {
		return returnColumns.filter((col) => displayColumns.includes(col.key as string));
	}, [returnColumns, displayColumns]);

	return (
		<CardWrapper
			styles={{
				paddingInline: 0,
				paddingBottom: 0,
				...style?.cardStyle,
			}}
		>
			<div
				style={{
					marginBottom: 24,
					marginInline: 24,
				}}
			>
				{contentAboveTable}
			</div>
			<div ref={tableContainerRef} className={`table-wrapper table-wrapper-${storeKey}`}>
				{isPortalReady &&
					tableContainerRef.current !== null &&
					ReactDOM.createPortal(
						<TableButtonsContainer
							isWithinPortal
							columns={returnColumns}
							displayColumns={displayColumns}
							removeItem={removeItem}
							setDisplayColumns={setDisplayColumns}
							refetch={refetch}
						/>,
						// @ts-ignore
						tableContainerRef.current.querySelector(".col-sm-6.form-inline.p-0.c-datatable-filter")
					)}
				<div className="table-header">
					<CDataTable
						loadingSlot={
							<LoadingOverlay
								visible={Boolean(isLoading)}
								overlayBlur={2}
								loaderProps={{
									color: "yellow",
									size: "lg",
								}}
							/>
						}
						loading={isLoading}
						addTableClasses="data-table"
						itemsPerPageSelect={{
							label: t("smsNotifications.table.rowsPerPage"),
							values: [5, 10, 50, 100, 200, 300, 400, 500],
						}}
						columnFilter={withFilters}
						sorter={withSorter}
						items={data}
						fields={filteredColumns}
						itemsPerPage={itemsPerPage}
						tableFilter={{
							label: "‎ ",
							placeholder: newSearchPlaceholder,
						}}
						scopedSlots={scopedSlots}
						pagination={withPagination}
					/>
				</div>
			</div>
		</CardWrapper>
	);
}
