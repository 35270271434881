import { Avatar, Flex, Stack, Text } from "@mantine/core";
import moment from "moment";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ISmsNotfication } from "../interfaces/ISmsNotifications";
import CustomTooltip from "./CustomTooltip";
import { IColumnModel } from "./DataTableWrapper";
import DataTableWrapperSimple from "./DataTableWrapperSimple";
import PhoneButtonForClientInfo from "./PhoneButtonForClientInfo";
import RedirectToOrderPageButton from "./RedirectToOrderPageButton";
import ResendSmsModal from "./ResendSmsModal";
import SmsStatusColumn from "./SmsStatusColumn";
import TitleAndDescription from "./TitleAndDescription";

export const dateFormat = "ddd DD MMM YYYY / HH:mm:ss";

const columns = (
	t: any,
	returnModule: (
		orderId?: string,
		horecaOrderId?: string,
		clothesOrderId?: string
	) =>
		| {
				module: string;
				id: string;
		  }
		| undefined,
	refetch: () => void
): IColumnModel<ISmsNotfication & { readableStatus: string }>[] => {
	return [
		{
			key: "imageUrl",
			label: t("smsNotifications.table.columns.provider"),
			filter: false,
			sorter: false,
			content: (item: ISmsNotfication) => {
				return (
					<Avatar
						style={{
							border: "1px solid var(--divider40)",
						}}
						styles={{
							image: {
								objectFit: "contain",
							},
						}}
						size={60}
						src={item.imageUrl}
						variant="outline"
						radius={1000}
					/>
				);
			},
		},
		{
			key: "createdAt",
			label: t("smsNotifications.table.columns.createdAt"),
			sorter: true,
			filter: true,
			_style: {
				width: "160px",
			},
			content: (item: ISmsNotfication) => {
				return (
					<TitleAndDescription
						title={moment(item.createdAt, dateFormat).format("ddd, DD MMM YYYY")}
						description={moment(item.createdAt, dateFormat).format("HH:mm")}
					/>
				);
			},
		},
		{
			key: "deliveredAt",
			label: t("smsNotifications.table.columns.deliveredAt"),
			_style: {
				width: "160px",
			},
			sorter: true,
			filter: true,
			content: (item: ISmsNotfication) => {
				return item.deliveredAt ? (
					<TitleAndDescription
						title={moment(item.deliveredAt, dateFormat).format("ddd, DD MMM YYYY")}
						description={moment(item.deliveredAt, dateFormat).format("HH:mm")}
					/>
				) : (
					<>N/A</>
				);
			},
		},
		{
			key: "recipientPhoneNumber",
			label: t("smsNotifications.table.columns.client"),
			sorter: true,
			filter: true,
			content: (item: ISmsNotfication) => {
				return (
					<PhoneButtonForClientInfo clientId={item.clientId} phone={item.recipientPhoneNumber} />
				);
			},
		},
		{
			key: "creditsUsed",
			label: t("smsNotifications.table.columns.creditsUsed"),
			sorter: true,
			filter: true,
			content: (item: ISmsNotfication) => {
				return (
					<Text size={14} color="var(--body)">
						{t("smsNotifications.table.columns.credits", { credits: item.creditsUsed })}
					</Text>
				);
			},
		},
		{
			key: "textSms",
			label: t("smsNotifications.table.columns.message"),
			_style: {
				// width: "40%",
			},
			filter: true,
			sorter: false,
			content: (item: ISmsNotfication) => {
				return (
					<CustomTooltip
						multiline
						width={300}
						label={
							<Stack spacing={2}>
								{item.textSms.split(/\r?\n/).map((line, i) => (
									<Text size={14} color="var(--body)" key={i}>
										{line.trim()}
									</Text>
								))}
							</Stack>
						}
					>
						<Text lineClamp={1} color="var(--body)" size={14}>
							{item.textSms}
						</Text>
					</CustomTooltip>
				);
			},
		},
		{
			key: "readableStatus",
			label: t("smsNotifications.table.columns.status"),
			sorter: true,
			filter: true,
			content: (item: ISmsNotfication) => {
				return <SmsStatusColumn item={item} />;
			},
		},
		{
			key: "providerId",
			label: "",
			filter: false,
			sorter: false,
			content: (item: ISmsNotfication) => {
				const orderDetails = returnModule(item.orderId, item.horecaOrderId, item.clothesOrderId);
				return (
					<Flex align="center" gap={16}>
						<ResendSmsModal refetch={refetch} id={item._id} />
						<RedirectToOrderPageButton
							orderId={orderDetails?.id}
							// @ts-ignore
							module={orderDetails?.module}
						/>
					</Flex>
				);
			},
		},
	];
};

interface ISmsNotificationsTableProps {
	smsNotifications: ISmsNotfication[];
	refetch: () => void;
}

export default function SmsNotificationsTable({
	smsNotifications,
	refetch,
}: ISmsNotificationsTableProps) {
	const { t, i18n } = useTranslation();
	moment.locale(i18n.language);

	const returnModule = (orderId?: string, horecaOrderId?: string, clothesOrderId?: string) => {
		if (orderId) {
			return { module: "covoare", id: orderId };
		} else if (horecaOrderId) {
			return { module: "horeca", id: horecaOrderId };
		} else if (clothesOrderId) {
			return { module: "haine", id: clothesOrderId };
		} else return;
	};

	const returnReadableStatus = (status: string) => {
		switch (status) {
			case "error-sending":
				return t("smsNotifications.table.status.error");
			case "delivered":
				return t("smsNotifications.table.status.sent");
			case "created":
				return t("smsNotifications.table.status.created");
			default:
				return "N/A";
		}
	};

	const tableData = useCallback(() => {
		const temp = smsNotifications.map((item, i) => ({
			...item,
			createdAt: moment(item.createdAt).format("ddd DD MMM YYYY / HH:mm:ss"),
			deliveredAt: item.deliveredAt
				? moment(item?.deliveredAt).format("ddd DD MMM YYYY/HH:mm:ss")
				: "",
			readableStatus: returnReadableStatus(item.status),
		}));
		return temp;
	}, [smsNotifications]);

	return (
		<DataTableWrapperSimple<ISmsNotfication & { readableStatus: string }>
			storeKey="sms-notifications-table"
			columns={columns(t, returnModule, refetch)}
			data={tableData()}
			refetch={refetch}
		/>
	);
}
