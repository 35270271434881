import useCreateAppNotification from "@hooks/notifications/useCreateAppNotification";
import useDeleteAppNotification from "@hooks/notifications/useDeleteAppNotification";
import { NotificationType } from "@hooks/notifications/useGetAppNotifications";
import useUpdateAppNotification from "@hooks/notifications/useUpdateAppNotification";
import {
	ActionIcon,
	Button,
	Flex,
	Modal,
	Select,
	Stack,
	Textarea,
	TextInput,
	Tooltip,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { IconPencil, IconTrash } from "@tabler/icons";
import checkObjectsDifference from "@views/pages/settingsV2/form/checkObjectDifference";
import moment from "moment";
import { cloneElement, ReactElement, ReactNode, useCallback, useEffect, useState } from "react";
import { IUserNotificationResponse } from "../../interfaces/INotification";
import DateAndTimePicker from "./DateAndTimePicker";
import LinkInput from "./LinkInput";
import OrganizationsViewAndSelect from "./OrganizationsViewAndSelect";
import RoleCheckboxGroup from "./RoleCheckboxGroup";

export interface INotificationData {
	type: NotificationType;
	title: string;
	message: string;
	availableFrom: Date | null;
	organizationIds: string[];
	userRoles: string[];
	link?: {
		external: boolean;
		url: string;
	};
}

interface IAddEditNotificationModalProps {
	isDisabled?: boolean;
	actionType: "ADD" | "EDIT";
	notification?: IUserNotificationResponse;
	id?: string;
	customButton?: ReactNode;
	refetch: () => void;
}

const defaultValues = {
	type: NotificationType.WorkspaceSuccess,
	title: "",
	message: "",
	availableFrom: null,
	organizationIds: [],
	userRoles: [],
	link: {
		external: false,
		url: "",
	},
};

export default function AddEditNotificationModal({
	isDisabled,
	id,
	actionType,
	notification,
	customButton,
	refetch,
}: IAddEditNotificationModalProps) {
	const matches = useMediaQuery("(max-width: 768px)");
	const [availableFrom, setAvailableFrom] = useState<{
		date: Date | null;
		time: Date | null;
	}>({
		date: null,
		time: null,
	});
	const [notificationData, setNotificationData] = useState<INotificationData>(defaultValues);

	const [isOpened, { open, close }] = useDisclosure(false);

	const notificationTypeSelectData = Array.from(Object.values(NotificationType));

	const {
		mutate: createAppNotification,
		isLoading: isCreateLoading,
		isSuccess: isCreateSuccess,
	} = useCreateAppNotification();
	const {
		mutate: updateAppNotification,
		isLoading: isUpdateLoading,
		isSuccess: isUpdateSuccess,
	} = useUpdateAppNotification();
	const {
		mutate: deleteAppNotification,
		isLoading: isDeleteLoading,
		isSuccess: isDeleteSuccess,
	} = useDeleteAppNotification();

	const customButtonComponent =
		customButton &&
		cloneElement(customButton as ReactElement<any>, {
			onClick: () => open(),
			disabled: isDisabled,
		});

	const closeAndReset = () => {
		setNotificationData(defaultValues);
		setAvailableFrom({
			date: null,
			time: null,
		});
		close();
	};

	useEffect(() => {
		if (isCreateSuccess || isUpdateSuccess || isDeleteSuccess) {
			closeAndReset();
			refetch();
		}
	}, [isCreateSuccess, isUpdateSuccess, isDeleteSuccess]);

	useEffect(() => {
		if (isOpened && (availableFrom.date || availableFrom.time)) {
			const tempTime = moment(availableFrom.time).format("HH:mm");
			const tempDate = moment(availableFrom.date).format("DD/MM/YYYY");
			const temp = moment(`${tempDate}, ${tempTime}`, "DD/MM/YYYY, HH:mm").toDate();
			// @ts-ignore
			setNotificationData((prev) => ({
				...prev,
				availableFrom: temp,
			}));
		}
	}, [availableFrom, isOpened]);

	const returnRolesAndOrganizations = (notif?: IUserNotificationResponse) => {
		const tempOrganizationIds = notif?.organizations.map((item) => item.organizationId);
		const tempUserRoles = notif?.organizations.map((item) => {
			return item.usersRead
				.map((user) => {
					return user.role;
				})
				.flat();
		});
		const uniqueRoles = Array.from(new Set(tempUserRoles?.flat()));

		return {
			roles: uniqueRoles || [],
			orgs: tempOrganizationIds || [],
		};
	};
	const { orgs, roles } = returnRolesAndOrganizations(notification);

	useEffect(() => {
		if (isOpened && actionType === "EDIT" && notification) {
			const { createdAt, notificationId, organizations, updatedAt, metadata, ...rest } =
				notification;

			setNotificationData({
				...rest,
				organizationIds: orgs,
				userRoles: roles,
				link: metadata?.link,
			});

			const availableFromDate = rest.availableFrom ? new Date(rest.availableFrom) : null;
			setAvailableFrom({
				date: availableFromDate,
				time: availableFromDate,
			});
		}
	}, [isOpened, notification]);

	const checkObjectDiffs = useCallback(() => {
		let tempDiffs = {
			diffs: {},
			diffsArray: [],
		};
		if (isOpened && notification) {
			const { createdAt, notificationId, organizations, updatedAt, ...rest } = notification;
			const availableFromDate = rest.availableFrom ? new Date(rest.availableFrom) : null;
			const { dataToUpdateDiff, diffKeysArray } = checkObjectsDifference(
				{
					...rest,
					organizationIds: orgs,
					userRoles: roles,
					availableFrom: availableFromDate,
				},
				{
					...notificationData,
				}
			);
			tempDiffs = {
				diffs: dataToUpdateDiff,
				diffsArray: diffKeysArray,
			};
		}
		return {
			diffs: tempDiffs.diffs,
			diffsArray: tempDiffs.diffsArray,
		};
	}, [notification, notificationData, isOpened]);

	const { diffs, diffsArray } = checkObjectDiffs();

	const submit = () => {
		switch (actionType) {
			case "ADD":
				createAppNotification({
					...notificationData,
				});
				break;
			case "EDIT":
				if (diffsArray.length) {
					updateAppNotification({
						id: id as string,
						data: { ...diffs },
					});
				}
				break;
		}
	};

	const returnDisabled = () => {
		let tempDisabled = true;
		switch (actionType) {
			case "ADD":
				const { title, message, organizationIds, type, userRoles } = notificationData;
				if (title && message && type && organizationIds.length && userRoles.length) {
					tempDisabled = false;
				}
				break;
			case "EDIT":
				if (diffsArray.length) {
					tempDisabled = false;
				}
				break;
		}
		return tempDisabled;
	};

	return (
		<>
			<Modal
				opened={isOpened}
				onClose={closeAndReset}
				styles={{
					modal: {
						width: "100%",
						maxWidth: "700px",
					},
				}}
				zIndex={2000}
				radius={20}
				centered
				title="Notificare noua"
			>
				<Stack spacing={24}>
					<Select
						value={notificationData.type}
						onChange={(val) =>
							setNotificationData((prev) => ({
								...prev,
								type: val as NotificationType,
							}))
						}
						data={notificationTypeSelectData}
						label="Tip"
						classNames={{
							input: "input-styles",
							item: "select-item",
						}}
					/>
					<TextInput
						value={notificationData.title}
						onChange={(e) => {
							setNotificationData((prev) => ({
								...prev,
								title: e.target.value,
							}));
						}}
						classNames={{
							input: "input-styles",
						}}
						label="Titlu"
					/>
					<Textarea
						value={notificationData.message}
						onChange={(e) => {
							setNotificationData((prev) => ({
								...prev,
								message: e.target.value,
							}));
						}}
						classNames={{
							input: "input-styles",
						}}
						label="Descriere"
						minRows={5}
					/>
					<LinkInput
						notificationData={notificationData}
						setNotificationData={setNotificationData}
					/>
					<OrganizationsViewAndSelect
						setNotificationData={setNotificationData}
						actionType={actionType}
						notificationData={notificationData}
					/>
					<RoleCheckboxGroup
						actionType={actionType}
						notificationData={notificationData}
						setNotificationData={setNotificationData}
					/>
					<DateAndTimePicker availableFrom={availableFrom} setAvailableFrom={setAvailableFrom} />
					<Flex
						wrap={matches ? "wrap" : "nowrap"}
						align="center"
						justify="flex-end"
						gap={16}
						w="100%"
					>
						<Button
							onClick={closeAndReset}
							w={matches ? "100%" : "auto"}
							classNames={{
								root: "button-style",
							}}
							color="dark"
							variant="subtle"
						>
							Anulare
						</Button>
						<Button
							disabled={returnDisabled()}
							loading={isCreateLoading || isUpdateLoading || isDeleteLoading}
							onClick={submit}
							color="yellow"
							w={matches ? "100%" : "auto"}
							classNames={{
								root: "button-style",
							}}
						>
							{actionType === "EDIT" ? "Editeaza" : "Creaza"}
						</Button>
					</Flex>
				</Stack>
			</Modal>
			{customButtonComponent ? (
				customButtonComponent
			) : (
				<>
					<Tooltip withArrow label="Editeaza">
						<ActionIcon onClick={open}>
							<IconPencil color="var(--accent)" />
						</ActionIcon>
					</Tooltip>
					<Tooltip withArrow label="Sterge">
						<ActionIcon onClick={() => deleteAppNotification(id as string)}>
							<IconTrash color="var(--error)" />
						</ActionIcon>
					</Tooltip>
				</>
			)}
		</>
	);
}
