import { Avatar, Group, Select, Text } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { companyLanguage } from "@reusable/LanguangeSelector/TimezoneAndName";
import { changeLanguage } from "i18next";
import { forwardRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IOffer } from "../../interfaces/IOffer";

interface ItemProps {
	value: string;
	label: string;
	image: string;
}

interface ILanguageSelectProps {
	offerForm: UseFormReturnType<IOffer, (values: IOffer) => IOffer>;
}

export default function LanguageSelect({ offerForm }: ILanguageSelectProps) {
	const selectLanguages = ["ro", "en"];
	const { i18n } = useTranslation();

	const selectData = companyLanguage.filter((item) =>
		selectLanguages.includes(item.value.toLowerCase())
	);

	const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
		({ image, label, ...others }: ItemProps, selectItemRef) => (
			<div {...others} ref={selectItemRef}>
				<Group noWrap>
					<Avatar size={24} src={image} />

					<div>
						<Text>{label}</Text>
					</div>
				</Group>
			</div>
		)
	);

	const changePDFLanguage = (val: string | null) => {
		if (val) {
			const lang = val.toLocaleLowerCase();
			changeLanguage(lang);
			localStorage.setItem("language", lang);
			offerForm.setFieldValue("language", lang);
		}
	};

	useEffect(() => {
		const lang = offerForm.values.language;
		changeLanguage(lang);
		localStorage.setItem("language", lang);
	}, [offerForm.values.language, i18n.language]);

	return (
		<Select
			onChange={(val) => changePDFLanguage(val)}
			value={offerForm.values.language}
			w="100%"
			label="Limba"
			data={selectData}
			classNames={{
				input: "input-styles",
				item: "select-item",
			}}
			itemComponent={SelectItem}
		/>
	);
}
