import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

export function useSearchParams() {
	const { search, pathname } = useLocation();
	const history = useHistory();

	// Create a URLSearchParams instance from the current search string
	const params = useMemo(() => new URLSearchParams(search), [search]);

	// Function to update query parameters and sync with the URL
	const setSearchParams = (
		newParams: URLSearchParams | Record<string, string>,
		options: { merge?: boolean } = { merge: false }
	) => {
		if (newParams instanceof URLSearchParams) {
			history.push(`${pathname}?${newParams.toString()}`);
		} else {
			const updatedParams = new URLSearchParams(
				options.merge ? search : "" // Merge with existing params if `merge` is true
			);
			Object.entries(newParams).forEach(([key, value]) => updatedParams.set(key, value));
			history.push(`${pathname}?${updatedParams.toString()}`);
		}
	};

	const removeParam = (itemToRemove: string) => {
		params.delete(itemToRemove);
		history.push(`${pathname}?${params.toString()}`);
	};

	return [params, setSearchParams, removeParam] as const;
}
