export const selectData = (
	item: string,
	selectedData?: string[],
	setSelectedData?: (val: any) => void
) => {
	if (selectedData && setSelectedData) {
		if (selectedData?.includes(item)) {
			const tempSelected = selectedData.filter((val) => val !== item);
			setSelectedData && setSelectedData(tempSelected);
		} else {
			setSelectedData && setSelectedData([...selectedData, item]);
		}
	}
};

export const selectOrDeselectAllData = (
	data: any[],
	selectKey?: string,
	selectedData?: string[],
	setSelectedData?: (val: any) => void,
) => {
	if (selectedData?.length === data.length) {
		setSelectedData && setSelectedData([]);
	} else {
		const tempData = selectKey && data.map((item) => item[selectKey]);
		setSelectedData && setSelectedData(tempData);
	}
};

export const checkboxState = (
	checkFor: "intermediate" | "checked",
	data: any[],
	selectedData?: string[],
) => {
	switch (checkFor) {
		case "intermediate":
			return Boolean(selectedData?.length && selectedData?.length !== data.length);
		case "checked":
			return Boolean(selectedData?.length && selectedData?.length === data.length);
		default:
			return false;
	}
};
