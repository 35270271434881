import { Flex, Tooltip } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons";
import SmsStatusBadge from "@views/smsNotifications/components/SmsStatusBadge";
import { ESmsNotificationStatus } from "@views/smsNotifications/interfaces/ISmsNotifications";
import moment from "moment";
import { IExtendedRow } from "./NotificationsTable";

interface INotificationStatusColumnProps {
	extendedNotification: IExtendedRow;
	returnReadableStatus: (availableFrom?: Date | null) => {
		label: string;
		value: ESmsNotificationStatus;
	};
}

export default function NotificationStatusColumn({
	extendedNotification,
	returnReadableStatus,
}: INotificationStatusColumnProps) {
	const isGreaterThanCurrent = extendedNotification.availableFrom
		? moment(extendedNotification?.availableFrom).isAfter(moment().date())
		: false;
	const availableFromDate = moment(extendedNotification?.availableFrom).format("DD.MM.YYYY, HH:mm");
	const status = returnReadableStatus(extendedNotification?.availableFrom).value;

	return (
		<Flex gap={4} align="center">
			<SmsStatusBadge
				status={status}
				customLabels={{
					success: "Trimis",
					pending: `Programat - ${moment(extendedNotification?.availableFrom).format("DD.MM.YYYY, HH:mm")}`,
				}}
			/>
			<Tooltip
				disabled={!isGreaterThanCurrent && status !== ESmsNotificationStatus.DELIVERED}
				withArrow
				label={availableFromDate}
			>
				<div>
					<IconInfoCircle
						visibility={isGreaterThanCurrent ? "visible" : "hidden"}
						size={16}
						color="var(--body)"
					/>
				</div>
			</Tooltip>
		</Flex>
	);
}
