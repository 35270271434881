import { Flex, Text, UnstyledButton } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons";
import { ClientDetailsDrawer } from "@views/orders/SmallerComponents/ClientDetailsDrawer";

interface IPhoneButtonForClientInfoProps {
	phone: string;
	clientId: string;
}

export default function PhoneButtonForClientInfo({
	phone,
	clientId,
}: IPhoneButtonForClientInfoProps) {
	return (
		<ClientDetailsDrawer
			customButton={
				<UnstyledButton className="text-underline-hover" color="var(--body)">
					<Flex align="center" gap={2}>
						<Text size={14} color="var(--body)">
							{phone}
						</Text>
						<IconExternalLink stroke={1.5} size={18} color="var(--body)" />
					</Flex>
				</UnstyledButton>
			}
			clientId={clientId}
		/>
	);
}
