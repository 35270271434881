import useGetAvailableOffers from "@hooks/offer/useGetAvailableOffers";
import { Flex, Select } from "@mantine/core";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { IDataToRegister } from "../Register";
import { IOfferResponse } from "../interfaces/IOffer";
import OfferIframePreview from "./OfferIframePreview";

interface ISelectOffers {
	label: string;
	value: string;
	data: IOfferResponse;
}

interface IOfferSelectProps {
	setDataToRegister: Dispatch<SetStateAction<IDataToRegister>>;
}

export default function OfferSelect({ setDataToRegister }: IOfferSelectProps) {
	const [selectOffers, setSelectOffers] = useState<ISelectOffers[]>([]);
	const [pdfPreview, setPdfPreview] = useState({
		title: "",
		pdfUrl: "",
	});
	const { data, isSuccess } = useGetAvailableOffers();

	useEffect(() => {
		if (data && isSuccess) {
			const tempData = data.map((item: any) => ({
				value: item._id,
				label: `${item.companyName} - ${item.companyCUI}`,
				data: item,
			}));
			setSelectOffers(tempData);
		}
	}, [data, isSuccess]);

	const pickOffer = (id: string) => {
		const offer = selectOffers.find((item) => item.value === id);
		if (offer) {
			setDataToRegister((prev) => ({
				...prev,
				organizationToCreate: {
					...prev.organizationToCreate,
					organizationNameEntity: offer.data.companyName,
					organizationCif: offer.data.companyCUI,
					organizationRegCom: offer.data.companyJ,
					administratorName: offer.data.companyAdministratorName,
					// organizationAddress: "",
					organizationEmail: offer.data.companyEmail,
					organizationPhone: offer.data.companyPhone,
					offerId: offer.data._id,
				},
			}));

			setPdfPreview({
				title: `Oferta ${offer.data.offerShortId}`,
				pdfUrl: offer.data.offerUrl,
			});
		}
	};

	return (
		<Flex gap={8} align="flex-end">
			<Select
				w="100%"
				searchable
				label="Oferta"
				onChange={(val) => pickOffer(val || "")}
				data={selectOffers}
			/>
			<OfferIframePreview pdfUrl={pdfPreview.pdfUrl} title={pdfPreview.title} />
		</Flex>
	);
}
