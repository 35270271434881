export interface IOffer {
	name: string;
	price: number;
	startSubscription: Date;
	features: any;
	companyName: string;
	companyCUI: string;
	companyJ: string;
	companyAddress: string;
	companyAdministratorName: string;
	// invoiceBase64?: string;
	html?: string;
	companyEmail: string;
	companyPhone: string;
	offerShortId: string;
	language: string;
	organizationId?: string;
	invoicePeriod?: number;
}

export interface IOfferResponse {
	_id: string;
	name: string;
	price: number;
	startSubscription: Date | string;
	features: any;
	companyName: string;
	companyCUI: string;
	companyJ: string;
	companyAddress: string;
	companyAdministratorName: string;
	companyEmail: string;
	companyPhone: string;
	offerShortId: string;
	organizationId?: string;
	offerUrl: string;
	createdAt: Date | string;
	updatedAt: Date | string;
}

export interface IOrganizationOffer extends IOfferResponse {
	invoiceList: IOfferInvoice[];
}

export interface IOfferInvoice {
	id: string;
	isCollected: boolean;
	isStorno: boolean;
	isStornoed: boolean;
	seriesName: string;
	number: string;
	issueDate: Date | string;
	dueDate: Date | string;
	currency: string;
	precision: number;
	total: string;
	invoiceLink: string;
	eInvoiceLink: string;
	type: string;
	clientId: string;
	clientCif: string;
	clientPhone: string;
	exchangeRate: number;
}

export enum EFieldType {
	ORDER = "order",
	SMS = "sms",
	TEXT = "text",
}

export interface IExtraOption {
	label: string;
	value: number;
	fieldType: EFieldType;
}

export enum EInvoiceType {
	INVOICE = "invoice",
	PROFORMA = "proforma",
	STORNO = "storno",
}
