import { Button } from "@mantine/core";
import { IconBallpen } from "@tabler/icons";
import { ClientInterface } from "@views/orders/SmallerComponents/ClientDetailsDrawer";
import { ReactElement, ReactNode, cloneElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

interface SendSmsPromotionsButtonProps {
	clientsIncoming?: ClientInterface[];
	customButton?: ReactNode;
}
function SendSmsPromotionsButton({ clientsIncoming, customButton }: SendSmsPromotionsButtonProps) {
	const history = useHistory();
	//@ts-ignore
	const isSuperAdmin = useSelector((state) => state.form.isSuperAdmin);
	const dispatch = useDispatch();
	//@ts-ignore
	const organizationSelected = useSelector((state) => state.form.organizationSelected);

	const customButtonElement =
		customButton &&
		cloneElement(customButton as ReactElement<any>, {
			onClick: () => {
				dispatch({ type: "set", clientsSelectedForCampaign: clientsIncoming });
				//wait 500ms for the state to update
				setTimeout(() => {
					history.push("/campanii/campanie-noua");
				}, 500);
			},
			disabled: !isSuperAdmin,
		});

	return (
		<>
			{customButtonElement ? (
				<>{customButtonElement}</>
			) : (
				<Button
					variant="filled"
					color="blue.7"
					rightIcon={<IconBallpen size={20} />}
					onClick={() => {
						dispatch({ type: "set", clientsSelectedForCampaign: clientsIncoming });
						//wait 500ms for the state to update
						setTimeout(() => {
							history.push("/campanii/campanie-noua");
						}, 500);
					}}
					disabled={!isSuperAdmin}
				>
					Creeaza campanie{" "}
					{clientsIncoming &&
						clientsIncoming.length > 0 &&
						`pentru (${clientsIncoming.length}) clienti`}
				</Button>
			)}
		</>
	);
}

export default SendSmsPromotionsButton;
