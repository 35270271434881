import { Libraries, useJsApiLoader } from "@react-google-maps/api";
import googleAPI from "@services/google";
import { useMemo } from "react";

const mapApiJs = "https://maps.googleapis.com/maps/api/js";
export function useGoogleMapsInit() {
	const libraries: Libraries = useMemo(() => ["places"], []);
	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
		libraries,
	});
	const initMapScript = () => {
		// if script already loaded
		if (window.google) {
			return Promise.resolve();
		}
		const src = `${mapApiJs}?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&v=weekly`;
		return googleAPI.loadAsyncScript(src);
	};
	return { isLoaded, initMapScript };
}
