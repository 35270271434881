import { hideNotification } from "@mantine/notifications";
import {
	showNotificationError,
	showNotificationLoading,
	showNotificationSuccess,
} from "@reusable/Notifications/notificationSystem";
import { updateNotification } from "@services/notifications/appNotifications";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { ICreateAppNotification } from "./useCreateAppNotification";

export default function useUpdateAppNotification() {
	const randomId = Math.random().toString(36).substring(7);
	const { t } = useTranslation();

	return useMutation(
		({ id, data }: { id: string; data: Partial<ICreateAppNotification> }) =>
			updateNotification(id, data),
		{
			retry: false,
			onSuccess: async () => {
				showNotificationSuccess(t("Success"));
				hideNotification(randomId);
			},
			onError: async (error: string) => {
				showNotificationError(t("Error"));
				hideNotification(randomId);
			},
			onMutate: async () => {
				showNotificationLoading(t("Loading..."), randomId);
			},
		}
	);
}
