import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import { IconRefresh } from "@tabler/icons";
import { useTranslation } from "react-i18next";
import { IColumnModel } from "../components/DataTableWrapper";
import ToggleColumnsButton from "../components/ToggleColumnsButton";

interface ITableButtonsContainerProps<TData> {
	refetch?: () => void;
	removeItem: () => void;
	columns: IColumnModel<TData>[];
	displayColumns: string[];
	setDisplayColumns: (val: string[] | ((prevState: string[]) => string[])) => void;
	isWithinPortal?: boolean
}

export default function TableButtonsContainer<TData>({
	refetch,
	removeItem,
	columns,
	displayColumns,
	setDisplayColumns,
	isWithinPortal
}: ITableButtonsContainerProps<TData>) {
	const { t } = useTranslation();

	return (
		<div
			style={{
				order: -1,
			}}
			className={isWithinPortal ? `newCol col-sm-${refetch ? "2" : "1"} p-0 ` : ""}
		>
			<Flex align="center" gap={16}>
				<ToggleColumnsButton<TData>
					removeItem={removeItem}
					columns={columns}
					displayedColumns={displayColumns}
					onChange={(newCols) => setDisplayColumns(newCols)}
				/>
				{refetch && (
					<Tooltip withArrow label={t("general.tableWrapper.tooltips.refresh")}>
						<ActionIcon variant="subtle" onClick={() => refetch()}>
							<IconRefresh color="var(--heading)" />
						</ActionIcon>
					</Tooltip>
				)}
			</Flex>
		</div>
	);
}
