import { hideNotification } from "@mantine/notifications";
import {
	showNotificationError,
	showNotificationLoading,
	showNotificationSuccess,
} from "@reusable/Notifications/notificationSystem";
import retrySendingSmsNotification from "@services/sms/retrySendingSmsNotification";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export default function useRetrySendingSmsNotification() {
	const randomId = Math.random().toString(36).substring(7);
	const { t } = useTranslation();

	return useMutation(
		(notificationId: string) => {
			return retrySendingSmsNotification(notificationId);
		},
		{
			retry: false,
			onSuccess: async () => {
				showNotificationSuccess(t("Success"));
				hideNotification(randomId);
			},
			onError: async (error: string) => {
				showNotificationError(t("Error"));
				hideNotification(randomId);
			},
			onMutate: async () => {
				showNotificationLoading(t("Loading..."), randomId);
			},
		}
	);
}
