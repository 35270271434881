import axiosInstance from "./axiosInstance";

const getAll = async () => {
	const response = await axiosInstance.get(`/v1/clients`).then((data) => {
		if (!(data.status === 200)) {
			throw new Error(data.status + " Failed Get Clients ");
		} else {
			return data.data;
		}
	});
	return response;
};

const deleteClient = async (_id) => {
	const response = await axiosInstance.delete(`/v1/client/${_id}`).then((data) => {
		if (!(data.status === 200)) {
			throw new Error(data.status + " Failed Delete Client ");
		} else {
			return data.data;
		}
	});
	return response;
};
const getClientsFiltered = async (filter) => {
	const response = await axiosInstance.get(`/v1/clients-filter?filter=${filter}`).then((data) => {
		if (!(data.status === 200)) {
			throw new Error(data.status + " Failed Get Clients ");
		} else {
			return data.data;
		}
	});
	return response;
};

const getOneClient = async (_id) => {
	const response = await axiosInstance.get(`/v1/clients/${_id}`).then((data) => {
		if (!(data.status === 200)) {
			throw new Error(data.status + " Failed Get Client ");
		} else {
			return data.data;
		}
	});
	return response;
};
export const updateClient = async (_id, client) => {
	if (!_id) return null;
	const response = await axiosInstance.patch(`/v1/clients/${_id}`, client).then((data) => {
		if (!(data.status === 200)) {
			throw new Error(data.status + " Failed Update Client ");
		} else {
			return data.data;
		}
	});
	return response;
};
const clientsApi = {
	getAll,
	deleteClient,
	getClientsFiltered,
	getOneClient,
	updateClient,
};

export default clientsApi;
