import { Flex } from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";
import { IconCalendarEvent } from "@tabler/icons";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";

interface IDateAndTimePickerProps {
	availableFrom: {
		date: Date | null;
		time: Date | null;
	};
	setAvailableFrom: Dispatch<
		SetStateAction<{
			date: Date | null;
			time: Date | null;
		}>
	>;
}

export default function DateAndTimePicker({
	availableFrom,
	setAvailableFrom,
}: IDateAndTimePickerProps) {
	const changeAvailableFromValue = (val: any, type: "date" | "time") => {
		if (type === "time") {
			// @ts-ignore
			setAvailableFrom((prev) => ({
				...prev,
				time: val,
			}));
		} else {
			// @ts-ignore
			setAvailableFrom((prev) => ({
				...prev,
				date: val,
				...(!prev.time && { time: moment().add(2, "hours").toDate() }),
			}));
		}
	};

	return (
		<Flex gap={16}>
			<DatePicker
				minDate={new Date()}
				value={availableFrom.date}
				rightSection={<IconCalendarEvent color="var(--grey)" />}
				onChange={(val) => changeAvailableFromValue(val, "date")}
				w="100%"
				label="Data"
				classNames={{
					input: "input-styles",
				}}
			/>
			<TimeInput
				value={availableFrom.time}
				onChange={(val) => changeAvailableFromValue(val, "time")}
				w={150}
				label="Ora"
				classNames={{
					input: "input-styles",
				}}
			/>
		</Flex>
	);
}
