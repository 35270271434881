import { NumberInput, Stack, Text, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { UseFormReturnType } from "@mantine/form";
import { IconCalendarEvent } from "@tabler/icons";
import { IOffer } from "../../interfaces/IOffer";

interface IOfferSectionProps {
	offerForm: UseFormReturnType<IOffer, (values: IOffer) => IOffer>;
}

export default function OfferSection({ offerForm }: IOfferSectionProps) {
	return (
		<Stack w="100%" spacing={24}>
			<Text size={20} fw={600} color="var(--heading)">
				Oferta propusa
			</Text>
			<TextInput
				defaultValue="Aplicatia Zarpet"
				disabled
				label="Produs"
				classNames={{
					input: "input-styles",
				}}
			/>
			<TextInput
				{...offerForm.getInputProps("name")}
				label="Nume plan"
				classNames={{
					input: "input-styles",
				}}
			/>
			<NumberInput
				value={offerForm.values.price}
				rightSectionWidth={60}
				rightSection="€/luna"
				label="Pret"
				classNames={{
					input: "input-styles",
				}}
				hideControls
				precision={2}
				onChange={(val) =>
					offerForm.setFieldValue("price", +parseInt(val?.toString() || "0").toFixed(2))
				}
			/>
			<DatePicker
				{...offerForm.getInputProps("startSubscription")}
				inputFormat="DD/MM/YYYY"
				rightSectionWidth={40}
				rightSection={<IconCalendarEvent />}
				label="Start subscriptie"
				classNames={{
					input: "input-styles",
				}}
			/>
			<NumberInput
				value={offerForm.values.invoicePeriod}
				rightSectionWidth={60}
				rightSection="luni"
				label="Perioada facturare"
				classNames={{
					input: "input-styles",
				}}
				hideControls
				precision={0}
				onChange={(val) => offerForm.setFieldValue("invoicePeriod", val || 1)}
			/>
		</Stack>
	);
}
