import { NotificationType } from "@hooks/notifications/useGetAppNotifications";
import { Badge, Flex, Stack, Text } from "@mantine/core";
import CustomTooltip from "@views/smsNotifications/components/CustomTooltip";
import { IColumnModel } from "@views/smsNotifications/components/DataTableWrapper";
import DataTableWrapperSimple from "@views/smsNotifications/components/DataTableWrapperSimple";
import { dateFormat } from "@views/smsNotifications/components/SmsNotificationsTable";
import TitleAndDescription from "@views/smsNotifications/components/TitleAndDescription";
import { ESmsNotificationStatus } from "@views/smsNotifications/interfaces/ISmsNotifications";

import moment from "moment";
import { useCallback } from "react";
import { IUserNotificationResponse } from "../../interfaces/INotification";
import AddEditNotificationModal from "./AddEditNotificationModal";
import NotificationStatusColumn from "./NotificationStatusColumn";
import ReadDrawer from "./ReadDrawer";

const columns = (
	returnReadableStatus: (availableFrom?: Date | null) => {
		label: string;
		value: ESmsNotificationStatus;
	},
	refetch: () => void
): IColumnModel<IExtendedRow>[] => {
	return [
		{
			key: "index",
			label: "#",
			sorter: true,
			filter: true,
			_style: {
				width: 80,
			},
		},
		{
			key: "createdAt",
			label: "Data",
			sorter: true,
			filter: true,
			_style: {
				width: "160px",
			},
			content: (item) => {
				return (
					<TitleAndDescription
						title={moment(item.createdAt, dateFormat).format("ddd, DD MMM YYYY")}
						description={moment(item.createdAt, dateFormat).format("HH:mm")}
					/>
				);
			},
		},
		{
			key: "title",
			label: "Titlu",
			sorter: true,
			filter: true,
		},
		{
			key: "message",
			label: "Descriere",
			_style: {},
			filter: true,
			sorter: false,
			content: (item) => {
				return (
					<CustomTooltip
						multiline
						width={300}
						label={
							<Stack spacing={2}>
								{item.message?.split(/\r?\n/).map((line, i) => (
									<Text size={14} color="var(--body)" key={i}>
										{line.trim()}
									</Text>
								))}
							</Stack>
						}
					>
						<Text lineClamp={1} color="var(--body)" size={14}>
							{item.message}
						</Text>
					</CustomTooltip>
				);
			},
		},
		{
			key: "readableType",
			label: "Tip",
			sorter: true,
			filter: true,
			content: (item) => {
				return (
					<Badge
						radius={6}
						style={{
							border: "1px solid var(--divider)",
						}}
						styles={{
							root: {
								padding: "4px 8px",
								height: "auto",
							},
						}}
						bg="var(--divider20)"
						c="var(--heading)"
					>
						{item.readableType}
					</Badge>
				);
			},
		},
		{
			key: "readableStatus",
			label: "Status",
			sorter: true,
			filter: true,
			content: (item) => {
				return (
					<NotificationStatusColumn
						extendedNotification={item}
						returnReadableStatus={returnReadableStatus}
					/>
				);
			},
		},
		{
			key: "notificationId",
			label: "",
			sorter: false,
			filter: false,
			content: (item) => {
				return (
					<Flex gap={16}>
						<ReadDrawer notification={item} />
						<AddEditNotificationModal
							refetch={refetch}
							id={item.notificationId}
							notification={item}
							actionType="EDIT"
						/>
					</Flex>
				);
			},
		},
	];
};

export interface IExtendedRow extends IUserNotificationResponse {
	index: string;
	readableType: string;
	readableStatus: string;
}

interface INotificationsTableProps {
	notifications: IUserNotificationResponse[];
	refetch: () => void;
}

export default function NotificationsTable({ notifications, refetch }: INotificationsTableProps) {
	const returnReadableType = (notifType: NotificationType) => {
		switch (notifType) {
			// case NotificationType.Feedback:
			// 	return "Feedback";
			case NotificationType.WorkspaceAlert:
				return "Alerta aplicatie";
			case NotificationType.SystemAlert:
				return "Alerta sistem";
			case NotificationType.WorkspaceSuccess:
				return "Aplicatie";
		}
	};

	const returnReadableStatus = (availableFrom?: Date | null) => {
		let tempStatus = {
			label: "Eroare",
			value: ESmsNotificationStatus.ERROR_SENDING,
		};
		if (availableFrom) {
			const isGreaterThanCurrent = moment(availableFrom).isAfter(moment());

			if (isGreaterThanCurrent) {
				tempStatus = {
					label: `Programat - ${moment(availableFrom).format("DD.MM.YYYY, HH:mm")}`,
					value: ESmsNotificationStatus.CREATED,
				};
			} else {
				tempStatus = {
					label: `Trimis`,
					value: ESmsNotificationStatus.DELIVERED,
				};
			}
		} else {
			tempStatus = {
				label: `Trimis`,
				value: ESmsNotificationStatus.DELIVERED,
			};
		}
		return tempStatus;
	};

	const tableData = useCallback(() => {
		return notifications.map((item, i) => ({
			...item,
			index: `${i + 1}.`,
			readableType: returnReadableType(item.type as NotificationType),
			readableStatus: returnReadableStatus(item?.availableFrom).label,
			createdAt: moment(item.createdAt).format("ddd DD MMM YYYY / HH:mm:ss"),
			title: item.title || "-",
		}));
	}, [notifications]);

	return (
		<DataTableWrapperSimple<IExtendedRow>
			columns={columns(returnReadableStatus, refetch)}
			data={tableData()}
			storeKey="app-notification-table"
		/>
	);
}
