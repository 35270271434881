import { ICreateAppNotification } from "@hooks/notifications/useCreateAppNotification";
import axiosInstance from "@services/axiosInstance";

// Function to create a notification
export async function createNotification(data: ICreateAppNotification) {
	const response = await axiosInstance
		.post("/v2/app-notifications", {
			...data,
		})
		.then((data) => {
			if (data.status !== 201) {
				throw new Error(data.status + " Failed to create notification");
			} else {
				return data.data; // Assuming the API returns the created notification data
			}
		})
		.catch((error) => {
			console.error("Error creating notification:", error);
			throw new Error("Error creating notification: " + error.message);
		});
	return response;
}

// Function to fetch all notifications for a user
export async function getUserNotifications(userId: string) {
	const response = await axiosInstance
		.get("/v2/app-notifications/" + userId)
		.then((data) => {
			if (data.status !== 200) {
				throw new Error(data.status + " Failed to fetch notifications");
			} else {
				return data.data; // Assuming the API returns a list of notifications
			}
		})
		.catch((error) => {
			console.error("Error fetching notifications:", error);
			throw new Error("Error fetching notifications: " + error.message);
		});
	return response;
}

// Function to mark a notification as read
export async function markNotificationAsRead(notificationId: string) {
	const response = await axiosInstance
		.put(`/v2/app-notifications/${notificationId}/read`)
		.then((data) => {
			if (data.status !== 200) {
				throw new Error(data.status + " Failed to mark notification as read");
			} else {
				return data.data; // Assuming the API returns the updated notification data
			}
		})
		.catch((error) => {
			console.error("Error marking notification as read:", error);
			throw new Error("Error marking notification as read: " + error.message);
		});
	return response;
}

// Function to delete a notification
export async function deleteNotification(notificationId: string) {
	const response = await axiosInstance
		.delete(`/v2/app-notifications/${notificationId}`)
		.then((data) => {
			if (data.status !== 200) {
				throw new Error(data.status + " Failed to delete notification");
			} else {
				return data.data; // Assuming the API returns the result of the deletion
			}
		})
		.catch((error) => {
			console.error("Error deleting notification:", error);
			throw new Error("Error deleting notification: " + error.message);
		});
	return response;
}

export async function getAllNotifications() {
	const response = await axiosInstance
		.get("/v2/app-notifications")
		.then((data) => {
			if (data.status !== 200) {
				throw new Error(data.status + " Failed to fetch notifications");
			} else {
				return data.data; // Assuming the API returns a list of notifications
			}
		})
		.catch((error) => {
			console.error("Error fetching notifications:", error);
			throw new Error("Error fetching notifications: " + error.message);
		});
	return response;
}

export async function updateNotification(id: string, data: Partial<ICreateAppNotification>) {
	const response = await axiosInstance
		.patch(`/v2/app-notifications/${id}/update`, data)
		.then((res) => {
			if (res.status !== 200) {
				throw new Error(res.status + " Failed to update notification");
			} else {
				return res.data;
			}
		})
		.catch((error: any) => {
			console.error("Error fetching notifications:", error);
			throw new Error("Error fetching notifications: " + error.message);
		});
	return response;
}
