import { ActionIcon, Tooltip } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons";
import { actionIconStyles } from "@views/orders/v2/readyForDelivery/components/ComponentsStyle";
import { cloneElement, ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface IRedirectToOrderPageButtonProps {
	orderId?: string;
	customButton?: ReactNode;
	module?: "covoare" | "horeca" | "haine";
}

export default function RedirectToOrderPageButton({
	orderId,
	customButton,
	module,
}: IRedirectToOrderPageButtonProps) {
	const { t } = useTranslation();
	let history = useHistory();

	const redirectToEdit = () => {
		switch (module) {
			case "covoare": {
				history.push(`/covoare/edit/${orderId}`);
				break;
			}
			case "horeca": {
				//history.push(`/horeca/edit/${orderId}`);
				break;
			}
			case "haine": {
				// find order
				// const order = ordersForRegistry.find((order: any) => order._id.toString() === orderId);
				// setClothesOrderSelected(order);
				break;
			}
			default: {
				break;
			}
		}
	};

	const newCustomButton =
		customButton &&
		cloneElement(customButton as ReactElement<any>, {
			onClick: () => {
				redirectToEdit();
			},
		});

	return newCustomButton ? (
		newCustomButton
	) : (
		<Tooltip label={t("smsNotifications.table.tooltips.openOrder")} withArrow>
			<ActionIcon
				size={actionIconStyles.size}
				disabled={!module}
				style={{
					background: "none",
					border: "none",
				}}
				variant="subtle"
				color="yellow"
				onClick={redirectToEdit}
			>
				<IconExternalLink />
			</ActionIcon>
		</Tooltip>
	);
}
