import { Flex, Stack } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { IOffer } from "../../interfaces/IOffer";
import CompanySection from "./CompanySection";
import ExtraOptionsSection from "./ExtraOptionsSection";
import IncludedOptionsSection from "./IncludedOptionsSection";
import LanguageSelect from "./LanguageSelect";
import OfferSection from "./OfferSection";
import PlanSelect from "./PlanSelect";

interface IOfferFormProps {
	offerForm: UseFormReturnType<IOffer, (values: IOffer) => IOffer>;
}

export default function OfferForm({ offerForm }: IOfferFormProps) {
	return (
		<Stack
			style={{
				flex: 1,
			}}
			w="100%"
			spacing={32}
		>
			<Flex gap={24}>
				<LanguageSelect offerForm={offerForm} />
				<PlanSelect offerForm={offerForm} />
			</Flex>
			<Flex gap={32}>
				<CompanySection offerForm={offerForm} />
				<OfferSection offerForm={offerForm} />
			</Flex>
			<IncludedOptionsSection offerForm={offerForm} />
			<ExtraOptionsSection offerForm={offerForm} />
		</Stack>
	);
}
