import { ISmsNotification } from "@hooks/notifications/useSendNotification";
import { ISmsNotificationPromotions } from "@hooks/notifications/useSendNotificationPromotions";
import axiosInstance from "@services/axiosInstance";

export async function sendNotifications(data: ISmsNotification) {
	const response = await axiosInstance
		.post(`/v1/notifications`, {
			...data,
		})
		.then((data) => {
			if (!(data.status === 200)) {
				throw new Error(data.status + " Failed Create Order ");
			} else {
				return data.data;
			}
		});
	return response;
}

export async function sendNotificationsPromotions(data: ISmsNotificationPromotions) {
	const response = await axiosInstance
		.post(`/v1/create-campaign`, {
			...data,
		})
		.then((data) => {
			if (!(data.status === 200)) {
				throw new Error(data.status + " Failed Create Order ");
			} else {
				return data.data;
			}
		});
	return response;
}

export async function getCampaignList() {
	const response = await axiosInstance
		.get(`/v1/campaign-list`)
		.then((data) => {
			if (!(data.status === 200)) {
				throw new Error(data.status + " Failed Create Order ");
			} else {
				return data.data;
			}
		});
	return response;
}
