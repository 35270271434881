import axiosInstance from "@services/axiosInstance";
import { IOffer } from "@views/pages/register/interfaces/IOffer";

export async function createOffer(data: IOffer) {
	const { language, ...rest } = data;
	try {
		const response = await axiosInstance.post("/v2/offer", {
			...rest,
		});

		if (response.status === 201) {
			return response.data;
		} else throw new Error(`Error generating offer, status: ${response.status}`);
	} catch (error: any) {
		console.log(error);
		throw new Error(error.message);
	}
}

export async function sendOfferEmail(id: string, language: string) {
	try {
		const response = await axiosInstance.post(`v2/offer/${id}/send`, { language });

		if (response.status === 200) {
			return response.data;
		} else throw new Error(`Error sending offer email, status: ${response.status}`);
	} catch (error: any) {
		console.log(error);
		throw new Error(error.message);
	}
}

export async function getOrganizationOffer(organizationId?: string) {
	if (organizationId) {
		try {
			const response = await axiosInstance.get(`/v2/offer/organization/${organizationId}`);

			if (response.status === 200) {
				return response.data;
			} else throw new Error(`Can't get the offers, status: ${response.status}`);
		} catch (error: any) {
			console.log(error);
			throw new Error(error.message);
		}
	} else return;
}

export async function getAvailableOffers() {
	try {
		const response = await axiosInstance.get("/v2/offers/available");

		if (response.status === 200) {
			return response.data;
		} else throw new Error(`Can't get the offers, status: ${response.status}`);
	} catch (error: any) {
		console.log(error);
		throw new Error(error.message);
	}
}
