import { UserType } from "@hooks/users/useEditUser";
import { Checkbox, Stack, Text } from "@mantine/core";
import { Dispatch, SetStateAction } from "react";
import { INotificationData } from "./AddEditNotificationModal";

interface IRoleCheckboxGroupProps {
	actionType: "ADD" | "EDIT";
	notificationData: INotificationData;
	setNotificationData: Dispatch<SetStateAction<INotificationData>>;
}

export default function RoleCheckboxGroup({
	actionType,
	notificationData,
	setNotificationData,
}: IRoleCheckboxGroupProps) {
	const userRolesData = Array.from(Object.values(UserType));

	const addOrRemoveRole = (val: UserType) => {
		// @ts-ignore
		if (notificationData.userRoles.includes(val)) {
			const tempRoles = notificationData.userRoles.filter((item) => item !== val);
			setNotificationData((prev) => ({
				...prev,
				userRoles: tempRoles,
			}));
		} else {
			// @ts-ignore
			setNotificationData((prev) => ({
				...prev,
				userRoles: [...prev.userRoles, val as string],
			}));
		}
	};

	return (
		<Stack spacing={8}>
			<Text size={14} fw={500}>
				Utilizatori
			</Text>
			<Stack spacing={8}>
				{userRolesData.map((item, i) => (
					<Checkbox
						disabled={actionType === "EDIT"}
						key={i}
						color="yellow"
						// @ts-ignore
						checked={notificationData.userRoles.includes(item)}
						label={item}
						value={item}
						onChange={(e) => addOrRemoveRole(e.target.value as UserType)}
					/>
				))}
			</Stack>
		</Stack>
	);
}
