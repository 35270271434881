import useGetAllAppNotifications from "@hooks/notifications/useGetAllAppNotifications";
import useCreateOffer from "@hooks/offer/useCreateOffer";
import useSendOfferEmail from "@hooks/offer/useSendOfferEmail";
import { Button, Flex, Stack, Text } from "@mantine/core";
import {
	IconBell,
	IconBuilding,
	IconClipboard,
	IconPlus,
	IconPrinter,
	IconReload,
} from "@tabler/icons";
import MenuButton from "@views/horeca/v2/companyList/components/MenuButton";
import { useSearchParams } from "@views/horeca/v2/companyList/utils/useSearchParamsCustom";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import ActivePageBreadCrumbs from "./components/notificationsComponents/ActivePageBreadCrumbs";
import AddEditNotificationModal from "./components/notificationsComponents/AddEditNotificationModal";
import CustomTabs from "./components/notificationsComponents/CustomTabs";
import Notifications from "./Notifications";
import Offer from "./Offer";
import OfferList from "./OfferList";
import Register from "./Register";
import useOfferForm from "./utils/useOfferForm";

const navigation = [
	{
		label: "Inregistrare",
		value: "register",
		icon: <IconBuilding />,
		disabled: false,
	},
	{
		label: "Creare oferta",
		value: "offer",
		icon: <IconPlus />,
		disabled: false,
	},
	{
		label: "Oferte",
		value: "offer-list",
		icon: <IconClipboard />,
		disabled: false,
	},
	{
		label: "Notificari",
		value: "notifications",
		icon: <IconBell />,
		disabled: false,
	},
];

export default function AdminPage() {
	const { t } = useTranslation();
	const history = useHistory();
	const [offerId, setOfferId] = useState("");
	const [params, setParams] = useSearchParams();
	const activeTabParam = params.get("active");
	const activeTabDetails = navigation.find((item) => item.value === activeTabParam);
	const {
		data: notifications,
		isSuccess: isGetNotificationsSuccess,
		refetch: refetchNotifications,
	} = useGetAllAppNotifications();
	
	const { offerForm, regenerateId } = useOfferForm();
	// @ts-ignore
	const isSoftwareAdmin = useSelector((state) => state.form.isSoftwareAdmin);

	useEffect(() => {
		if (!isSoftwareAdmin) {
			window.location.href = "/";
		}
	}, [isSoftwareAdmin]);

	const { mutate: createOffer, isSuccess, data, isLoading } = useCreateOffer();
	const {
		mutate: sendEmail,
		isSuccess: isSendSucces,
		isLoading: isSendLoading,
	} = useSendOfferEmail();

	const componentRef = useRef<HTMLDivElement>(null);

	const printOffer = useReactToPrint({
		contentRef: componentRef,
		documentTitle: t("admin.pdf.offerTitle", {
			date: moment().format("DD.MM.YYYY"),
		}),
	});

	useEffect(() => {
		if (!activeTabParam) {
			setParams({ active: "register" });
		}
	}, []);

	const handleCreateOffer = async () => {
		const element = document.getElementById("pdf-preview");
		if (element) {
			createOffer({
				data: offerForm.values,
				html: element.outerHTML,
			});
		}
	};

	useEffect(() => {
		if (isSuccess && data) {
			setOfferId(data._id);
		}
	}, [data, isSuccess]);

	useEffect(() => {
		if (isSendSucces) {
			setOfferId("");
		}
	}, [isSendSucces]);

	const returnComponent = () => {
		switch (activeTabParam) {
			case "offer":
				return (
					<Flex gap={16}>
						<Button
							loading={isLoading}
							disabled={activeTabParam !== "offer"}
							onClick={() => {
								handleCreateOffer();
							}}
							color="yellow"
							type="button"
							classNames={{
								root: "button-style",
							}}
						>
							Creaza oferta
						</Button>
						{offerId ? (
							<Button
								loading={isSendLoading}
								onClick={() => sendEmail({ id: offerId, language: offerForm.values.language })}
								variant="outline"
								color="dark"
								type="button"
								classNames={{
									root: "button-style",
								}}
							>
								Trimite email
							</Button>
						) : null}
						<MenuButton
							disabled={activeTabParam !== "offer"}
							items={[
								{
									label: "Printeaza",
									onClick: () => printOffer(),
									icon: <IconPrinter />,
								},
								{
									label: "Reseteaza",
									onClick: () => {
										offerForm.reset();
										regenerateId();
									},
									icon: <IconReload />,
								},
							]}
						/>
					</Flex>
				);
			case "notifications":
				return (
					<AddEditNotificationModal
						refetch={refetchNotifications}
						customButton={
							<Button
								color="yellow"
								classNames={{
									root: "button-style",
								}}
							>
								Creaza notificare
							</Button>
						}
						actionType="ADD"
						isDisabled={activeTabParam !== "notifications"}
					/>
				);
			default:
				return (
					<div
						style={{
							width: "200px",
						}}
					></div>
				);
		}
	};

	return (
		<>
			<Stack spacing={32} w="100%" p={32} pb={60}>
				<CustomTabs
					activeTab={activeTabParam}
					onTabChange={(tab) => setParams({ active: tab || "register" })}
					tabs={navigation}
					pannels={[
						{
							value: "register",
							component: <Register />,
						},
						{
							value: "offer",
							component: <Offer componentRef={componentRef} offerForm={offerForm} />,
						},
						{
							value: "offer-list",
							component: <OfferList offerForm={offerForm} />,
						},
						{
							value: "notifications",
							component: (
								<Notifications
									data={notifications}
									isSuccess={isGetNotificationsSuccess}
									refetch={refetchNotifications}
								/>
							),
						},
					]}
					tabsLeftSection={
						<ActivePageBreadCrumbs
							pageTitle={activeTabDetails?.label || ""}
							breadCrumbs={[
								<Text
									style={{
										cursor: "pointer",
									}}
									onClick={() => history.replace("/")}
									size={16}
									color="var(--bodyLight)"
								>
									Acasa /
								</Text>,
								"Admin",
								activeTabDetails?.label,
							]}
						/>
					}
					tabsRightSection={returnComponent()}
				/>
			</Stack>
		</>
	);
}
