import { ActionIcon, Modal, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconEye } from "@tabler/icons";
import React, { ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface IOfferIframePreviewProps {
	pdfUrl: string;
	title: string;
	color?: string;
	custom?: ReactNode;
}

export default function OfferIframePreview({
	pdfUrl,
	title,
	color = "#228BE6",
	custom,
}: IOfferIframePreviewProps) {
	const [isOpened, { open, close }] = useDisclosure(false);
	const isValid = Boolean(pdfUrl) && Boolean(title);
	const { t } = useTranslation();

	const customButton =
		custom &&
		React.cloneElement(custom as ReactElement<any>, {
			onClick: () => open(),
		});

	return (
		<>
			<Modal
				styles={{
					modal: {
						height: "80vh",
						width: "fit-content",
						backgroundColor: "transparent",
					},
					body: {
						height: "100%",
					},
				}}
				centered
				zIndex={2000}
				withCloseButton={false}
				opened={isOpened}
				onClose={close}
			>
				<div style={{ maxWidth: "850px", width: "850px", aspectRatio: "16/9" }}>
					<iframe
						loading="lazy"
						src={pdfUrl}
						style={{
							position: "absolute",
							top: 0,
							left: 0,
							width: "100%",
							height: "100%",
							border: "none",
							borderRadius: 20,
						}}
						title={title}
					></iframe>
				</div>
			</Modal>
			<Tooltip withArrow label={t("settings.v2.invoicing.seeOffer")}>
				{custom ? (
					customButton
				) : (
					<ActionIcon
						onClick={open}
						disabled={!isValid}
						w="100%"
						style={{ border: "1px solid var(--divider)" }}
						maw={36}
						h={36}
						variant="outline"
						radius={8}
					>
						<IconEye size={20} stroke={2} color={isValid ? color : "var(--grey)"} />
					</ActionIcon>
				)}
			</Tooltip>
		</>
	);
}
