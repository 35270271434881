import { ActionIcon, createStyles, Modal, Stack, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconEyeCheck } from "@tabler/icons";
import { IUserNotificationResponse } from "../../interfaces/INotification";
import OrganizationsAccordion from "./OrganizationsAccordion";

const useStyles = createStyles(() => ({
	modal: {
		borderRadius: 20,
		height: "calc(95vh - 48px)",
		alignSelf: "center",
		maxWidth: "500px",
		scrollbarWidth: "thin",
	},
	inner: {
		justifyContent: "flex-end",
		overflow: "hidden",
	},
	header: {
		padding: 24,
		paddingBottom: 0,
	},
}));

interface IReadDrawerProps {
	notification: IUserNotificationResponse;
}
export default function ReadDrawer({ notification }: IReadDrawerProps) {
	const { classes } = useStyles();
	const [isOpened, { open, close }] = useDisclosure(false);

	return (
		<>
			<Modal
				transition="slide-left"
				padding={0}
				title="Status notificari"
				size="lg"
				zIndex={2000}
				classNames={{
					modal: classes.modal,
					inner: classes.inner,
					header: classes.header,
				}}
				opened={isOpened}
				onClose={close}
				overflow="inside"
			>
				<Stack mb={24} spacing={24}>
					<OrganizationsAccordion organizations={notification.organizations} />
				</Stack>
			</Modal>
			<Tooltip withArrow label="Vizualizari">
				<ActionIcon variant="subtle" onClick={open}>
					<IconEyeCheck color="var(--accent)" />
				</ActionIcon>
			</Tooltip>
		</>
	);
}
