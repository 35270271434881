import { CCard, CCardBody, CCardHeader, CCol, CRow } from "@coreui/react";
import { useIsMobileScreen } from "@hooks/functional/useIsMobileScreen";
import useSendNotificationPromotions from "@hooks/notifications/useSendNotificationPromotions";
import {
	ActionIcon,
	Button,
	Checkbox,
	Grid,
	Text,
	TextInput,
	Textarea,
	Tooltip,
	createStyles,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { openConfirmModal } from "@mantine/modals";
import { IconArrowBackUp, IconBrandTelegram, IconX } from "@tabler/icons";
import { ClientInterface } from "@views/orders/SmallerComponents/ClientDetailsDrawer";
import "dayjs/locale/ro";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParentStylesPromotions } from "./PromotionList";

const useStyle = createStyles((theme) => ({
	container: {
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "center",
		gap: "20px",
		width: "100%",
		flexWrap: "wrap",
	},
	titleForList: {
		color: theme.colors.blue[7],
		fontFamily: "Lato, sans-serif",
		fontSize: "20px",
		fontWeight: 600,
		lineHeight: "25px",
		borderBottom: "0.5px solid " + theme.colors.blue[7],
		paddingBottom: "10px",
		width: "100%",
	},
	listContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		justifyContent: "center",
		gap: "16px",
	},
	labelInputs: {
		fontFamily: "Roboto, sans-serif",
		fontSize: "14px",
		fontWeight: 600,
		lineHeight: "16px",
		textAlign: "left",
	},
	phoneNumbersContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "flex-start",
		justifyContent: "flex-start",
		gap: "16px",
		flexWrap: "wrap",
	},
	phoneNumberBadge: {
		padding: "5px 8px 5px 12px",
		gap: "4px",
		borderRadius: "32px",
		border: "1px solid #323030",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		width: "fit-content",
		textSize: "10px",
		fontFamily: "Roboto, sans-serif",
		fontWeight: 400,
		lineHeight: "14px",
	},
	inputWithRightBottomLabel: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end",
		justifyContent: "center",
	},
}));

const MAX_PHONE_NUMBER_LENGTH = 200;
export function DisplayPhoneNumberList({
	phoneNumberList,
	removePhoneNumber,
}: {
	phoneNumberList: string[];
	removePhoneNumber?: (phoneNumber: string) => void;
}) {
	const { classes } = useStyle();
	return (
		<div className={classes.phoneNumbersContainer}>
			{phoneNumberList.map((phoneNumber, index) => (
				<div key={index} className={classes.phoneNumberBadge}>
					{index + 1}. {phoneNumber}
					{removePhoneNumber && (
						<ActionIcon onClick={() => removePhoneNumber(phoneNumber)} variant="subtle" size="xs">
							<IconX />
						</ActionIcon>
					)}
				</div>
			))}
		</div>
	);
}

const isMaximumPhoneNumberLength = (phoneNumberList: string[]) => {
	return phoneNumberList.length > MAX_PHONE_NUMBER_LENGTH;
};

export default function PromotionsClients() {
	const { t } = useTranslation();
	//@ts-ignore
	const clientsSelectedForCampaign = useSelector((state) => state.form.clientsSelectedForCampaign);
	const dispatch = useDispatch();
	const { classes } = useParentStylesPromotions();
	const { classes: classesLocal } = useStyle();
	const isMobile = useIsMobileScreen();
	//@ts-ignore
	const organizationSelected = useSelector((state) => state.form.organizationSelected);
	const { mutate, isLoading, isSuccess } = useSendNotificationPromotions();
	const [textMessage, setTextMessage] = useState("");
	const [phoneNumberList, setPhoneNumberList] = useState<string[]>([]);
	const [textAreaInput, setTextAreaInput] = useState("");
	const [campaignName, setCampaignName] = useState("");
	const [confirmationGDPR, setConfirmationGDPR] = useState(false);
	const [lastDayOfCampaign, setLastDayOfCampaign] = useState<Date | null>(null);
	const validateAndAddPhoneNumber = (phoneNumberFromTextArea: string) => {
		// Split the input string by commas and map over each element to remove spaces and non-numeric characters
		const phoneNumberList = phoneNumberFromTextArea.split(",").map(
			(phoneNumber) => phoneNumber.replace(/[^0-9]/g, "") // Removes anything that is not a digit
		);

		const seenNumbers = new Set(); // Set to track unique phone numbers
		const validPhoneNumberList = phoneNumberList.filter((phoneNumber) => {
			const phoneNumberRegex = /^[0-9]{10}$/;
			// Check if phone number matches the regex and hasn't been seen before
			if (phoneNumberRegex.test(phoneNumber) && !seenNumbers.has(phoneNumber)) {
				seenNumbers.add(phoneNumber); // Add to set if valid and not seen
				return true;
			}
			return false;
		});

		setPhoneNumberList([...validPhoneNumberList]);
	};

	useEffect(() => {
		if (clientsSelectedForCampaign && clientsSelectedForCampaign.length > 0) {
			const phoneNumbers = clientsSelectedForCampaign.map(
				(client: ClientInterface) => client.phone
			);
			setTextAreaInput(phoneNumbers.join(","));
		}
	}, [clientsSelectedForCampaign]);

	useEffect(() => {
		validateAndAddPhoneNumber(textAreaInput);
	}, [textAreaInput]);
	const isFormValid = useCallback(() => {
		return (
			textMessage.length > 0 &&
			phoneNumberList.length > 0 &&
			campaignName.length > 0 &&
			confirmationGDPR &&
			!isMaximumPhoneNumberLength(phoneNumberList) &&
			lastDayOfCampaign
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [textMessage, phoneNumberList, campaignName, confirmationGDPR, lastDayOfCampaign]);
	const removePhoneNumber = (phoneNumber: string) => {
		const newPhoneNumberList = phoneNumberList.filter((phone) => phone !== phoneNumber);
		setPhoneNumberList(newPhoneNumberList);
		// also remove from the textAreaInput
		const newTextAreaInput = textAreaInput
			.split(",")
			.filter((phone) => phone !== phoneNumber)
			.join(",");
		setTextAreaInput(newTextAreaInput);
	};

	const resetValues = () => {
		setTextMessage("");
		setTextAreaInput("");
		setCampaignName("");
		setPhoneNumberList([]);
		setConfirmationGDPR(false);
		dispatch({ type: "set", clientsSelectedForCampaign: [] });
	};
	useEffect(() => {
		if (isSuccess) {
			resetValues();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSuccess]);
	return (
		<>
			<CRow>
				<CCol xs="12" lg="12">
					<CCard>
						<CCardHeader className={classes.pageHeader}>
							<div className={classes.pageHeaderTitle}>Creeare promotie noua</div>
							<div className={isMobile ? classes.leftSideHeaderColumn : classes.leftSideHeaderRow}>
								<Button
									fullWidth
									size="sm"
									color="blue.7"
									variant="white"
									onClick={() => resetValues()}
									style={{
										width: "100%",
									}}
									rightIcon={<IconArrowBackUp size={20} />}
								>
									{t("general.buttonReset")}
								</Button>
								<Tooltip
									label={"Nu ati completat toate campurile necesare pentru a trimite mesajul."}
									position="top"
									events={{ hover: true, focus: true, touch: false }}
								>
									<Button
										variant="filled"
										color="blue.7"
										fullWidth
										sx={{ "&[data-disabled]": { pointerEvents: "all" } }}
										onClick={() => {
											openConfirmModal({
												title: "Confirmare creare campanie promotionala",
												centered: true,
												children: (
													<div
														style={{
															display: "flex",
															flexDirection: "column",
															alignItems: "flex-start",
															justifyContent: "center",
															gap: "20px",
														}}
													>
														<Text size="sm">
															Sunteti sigur ca doriti sa trimiteti mesajul la numerele de telefon
															introduse?
														</Text>
														<div>
															Mesaj: <strong>{textMessage}</strong>
														</div>
													</div>
												),
												labels: { confirm: "Trimite", cancel: "Anuleaza" },
												confirmProps: {
													color: "blue.7",
													rightIcon: <IconBrandTelegram size={20} />,
												},
												onConfirm: () => {
													mutate({
														phoneNumberList: phoneNumberList,
														smsMessageText: textMessage,
														campaignName: campaignName,
														lastDayOfCampaign: lastDayOfCampaign || new Date(),
													});
												},
											});
										}}
										loading={isLoading}
										disabled={!isFormValid()}
										rightIcon={<IconBrandTelegram size={20} />}
									>
										Trimite mesaje
									</Button>
								</Tooltip>
							</div>
						</CCardHeader>

						<CCardBody
							style={{
								marginTop: "24px",
							}}
						>
							<Grid gutter={32}>
								<Grid.Col md={6} lg={6}>
									<div className={classesLocal.listContainer}>
										<div className={classesLocal.titleForList}>Detalii campanie promotionala</div>
										<TextInput
											required
											w="100%"
											placeholder="Reducere 10% la toate produsele"
											label="Numele campaniei"
											onChange={(e) => setCampaignName(e.currentTarget.value)}
											value={campaignName}
											classNames={{
												label: classesLocal.labelInputs,
											}}
										/>
										<DatePicker
											w="100%"
											value={lastDayOfCampaign}
											onChange={setLastDayOfCampaign}
											label="Ultima zi a campaniei"
											required={true}
											classNames={{
												label: classesLocal.labelInputs,
											}}
											locale={organizationSelected.organizationLanguage}
											//minDate tomorrow
											minDate={new Date(new Date().getTime() + 24 * 60 * 60 * 1000)}
										/>
										<div className={classesLocal.inputWithRightBottomLabel}>
											<Textarea
												required
												w="100%"
												placeholder="0700000000,0987654321,1234567890"
												label="Lista de numere de telefon la care doriti sa trimiteti mesajul"
												onChange={(e) => {
													setTextAreaInput(e.currentTarget.value);
												}}
												value={textAreaInput}
												autosize
												minRows={2}
												classNames={{
													label: classesLocal.labelInputs,
												}}
											/>
											<Text
												size="xs"
												color={isMaximumPhoneNumberLength(phoneNumberList) ? "red" : "gray"}
											>
												{phoneNumberList.length}/{MAX_PHONE_NUMBER_LENGTH} (nr.selectate)
											</Text>
										</div>

										<div className={classesLocal.inputWithRightBottomLabel}>
											<Textarea
												required
												w="100%"
												value={textMessage}
												onChange={(e) => setTextMessage(e.currentTarget.value)}
												placeholder="Buna ziua, va anuntam ca avem o promotie de 10% la toate produsele. Va asteptam in magazin!"
												label="Mesaj sms de trimis"
												autosize
												minRows={2}
												maxLength={320}
											/>
											<Text size="xs" color={textMessage.length === 320 ? "red" : "gray"}>
												{textMessage.length}/320
											</Text>
										</div>

										<Checkbox
											required
											checked={confirmationGDPR}
											onChange={() => setConfirmationGDPR(!confirmationGDPR)}
											label="Confirm ca am primit acordul de la clientii selectati pentru a trimite mesaje promotionale"
										/>
									</div>
								</Grid.Col>
								<Grid.Col md={6} lg={6}>
									<div className={classesLocal.listContainer}>
										<div className={classesLocal.titleForList}>
											Lista numere de telefon selectate
										</div>

										{phoneNumberList && phoneNumberList.length > 0 && (
											<>
												<DisplayPhoneNumberList
													phoneNumberList={phoneNumberList}
													removePhoneNumber={removePhoneNumber}
												/>
											</>
										)}
									</div>
								</Grid.Col>
							</Grid>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
		</>
	);
}
