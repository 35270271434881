import useGetAvailableOffers from "@hooks/offer/useGetAvailableOffers";
import { UseFormReturnType } from "@mantine/form";
import DotsLoader from "@views/orders/v2/ordersHistory/components/DotsLoader";
import { useEffect, useState } from "react";
import OffersTable from "./components/offerComponents/OffersTable";
import { IOffer, IOfferResponse } from "./interfaces/IOffer";

interface IOfferListProps {
	offerForm: UseFormReturnType<IOffer, (values: IOffer) => IOffer>;
}

export default function OfferList({ offerForm }: IOfferListProps) {
	const [offers, setOffers] = useState<IOfferResponse[]>([]);
	const { data, isSuccess, isLoading } = useGetAvailableOffers();

	useEffect(() => {
		if (data && isSuccess) {
			setOffers(data);
		}
	}, [data, isSuccess]);

	return (
		<div>
			<DotsLoader isLoading={isLoading}>
				<OffersTable offerForm={offerForm} offers={offers} />
			</DotsLoader>
		</div>
	);
}
