import useRetrySendingSmsNotification from "@hooks/sms/useRetrySendingSmsNotification";
import { ActionIcon, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconReload } from "@tabler/icons";
import { actionIconStyles } from "@views/orders/v2/readyForDelivery/components/ComponentsStyle";
import WarningModal from "@views/pages/settingsV2/components/WarningModal";
import React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface IResendSmsModalProps {
	id: string;
	refetch: () => void;
}

export default function ResendSmsModal({ id, refetch }: IResendSmsModalProps) {
	const { t } = useTranslation();
	const [isOpened, { open, close }] = useDisclosure(false);
	const { mutate: retrySendingNotification, isSuccess: isRetrySuccess } =
		useRetrySendingSmsNotification();

	useEffect(() => {
		if (isRetrySuccess) {
			refetch();
			close();
		}
	}, [isRetrySuccess]);

	return (
		<>
			<WarningModal
				description={t("smsNotifications.table.resendModal.description")}
				title={t("smsNotifications.table.tooltips.resend")}
				isOpened={isOpened}
				onCancel={() => close()}
				onConfirm={() => retrySendingNotification(id)}
				confirmButtonLabel={t("smsNotifications.table.resendModal.confirmButton")}
			/>
			<Tooltip label={t("smsNotifications.table.tooltips.resend")} withArrow>
				<ActionIcon
				size={actionIconStyles.size}
					onClick={open}
					style={{
						background: "none",
						border: "none",
					}}
					variant="subtle"
					color="yellow"
				>
					<IconReload />
				</ActionIcon>
			</Tooltip>
		</>
	);
}
