import { Stack, Text } from "@mantine/core";
import { ISmsNotfication } from "../interfaces/ISmsNotifications";
import CustomTooltip from "./CustomTooltip";
import SmsStatusBadge from "./SmsStatusBadge";

interface ISmsStatusColumnProps {
	item: ISmsNotfication;
}

export default function SmsStatusColumn({ item }: ISmsStatusColumnProps) {
	return (
		<CustomTooltip
			isDisabled={!item.errorMessage}
			multiline
			width={300}
			position="top"
			label={
				<Stack spacing={8}>
					<Text color="var(--error)">
						Error:{" "}
						<Text color="var(--body)" component="span">
							{item.errorMessage || "-"}
						</Text>
					</Text>
					<Text color="var(--error)">
						Provider Error:
						<Text
							color="var(--body)"
							style={{
								wordWrap: "break-word",
							}}
						>
							{item.providerReturnedMessage}
						</Text>
					</Text>
				</Stack>
			}
		>
			<SmsStatusBadge status={item.status} />
		</CustomTooltip>
	);
}
